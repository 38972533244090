import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import ButtonMui from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

const useStyles = makeStyles(theme => {
	const palette = theme.palette.type
	return {
		error:		{ color: theme.palette.error[palette] },
		warning:	{ color: theme.palette.warning[palette] },
		success:	{ color: theme.palette.success[palette] },
	}
})

function BtnConfirm({ icon, text, onClick, anchorPop, setAnchorPop }) {
	const handleClick = () => {
		onClick()
		setAnchorPop()
	}

	return (
		<Popover
			open={!!anchorPop}
			anchorEl={anchorPop}
			onClose={() => setAnchorPop()}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<ButtonMui onClick={handleClick}>{icon} {text}</ButtonMui>
		</Popover>
	)
}

function Btn({ severity, icon, tooltip, onClick, confirmTxt, row }) {
	const [ anchorPop, setAnchorPop ] = useState()
	const styles = useStyles()
	
	const handleClick = (e) => {
		if(confirmTxt)
			setAnchorPop(e.currentTarget)
		else
			onClick(row)
	}

	let className = (severity && styles[severity]) ? styles[severity] : undefined

	let btnCode = <ButtonMui className={className} onClick={handleClick}>{icon}</ButtonMui>
	
	if(tooltip)
		btnCode = (
			<Tooltip title={tooltip}>
				{btnCode}
			</Tooltip>
		)

	if(!confirmTxt)
		return btnCode
	else
		return (
			<>
				{btnCode}
				<BtnConfirm
					icon={icon}
					text={confirmTxt}
					onClick={() => onClick(row)}
					anchorPop={anchorPop}
					setAnchorPop={setAnchorPop}
				/>
			</>
		)
}

export default function Button({ def, row, className }) {
	return (
		<ButtonGroup variant="contained" aria-label="contained primary button group" className={className}>
			{ def.map((btn, idx) => <Btn key={idx} row={row} {...btn} /> ) }
		</ButtonGroup>
	)
}
