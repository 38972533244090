import { FormCard, Input, Checkbox, Select } from './Form'

import optsProv from './opts-provinces'

export function FormCart({ data={}, withAddress, knownCustomer }) {
	//console.log('data', data)
	return (
		<FormCard xs={12} md={6} label="Carrello">
			<Input name="itemBrand" label="Marca" isRequired />
			<Input name="itemModel" label="Modello" isRequired />
			<Input name="itemNumber" label="Matricola" isRequired />
			<Input name="itemYear" label="Anno di costruzione" />
			<Input name="itemWorkHours" label="Conta ore" />
			{ Boolean(withAddress) && (
				<>
					{ Boolean(knownCustomer) && <Checkbox name="itemAddressEqCustomer" label="Il carrello si trova presso lo stesso indirizzo dalla sede legale" /> }
					<Input name="itemAddressTxt" label="Indirizzo" isRequired isDisabled={data.itemAddressEqCustomer} />
					<Input name="itemAddressLoc" label="Località" isRequired isDisabled={data.itemAddressEqCustomer} />
					<Select name="itemAddressProv" label="Provincia" options={optsProv} isRequired isDisabled={data.itemAddressEqCustomer} />
				</>
			)}
		</FormCard>
	)
}