import React, { useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

import { Table as MuiTable, TableBody, TableCell, TableHead, TableRow, TableContainer, TablePagination} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';

import {FormControl, InputLabel, Select, MenuItem} from '@material-ui/core';

import SearchIcon from '@material-ui/icons/Search'
import AddIcon from '@material-ui/icons/Add'

import APICtx from './lib/api'

const useStyles = makeStyles(theme => ({
	searchBox: {
		'margin-bottom': theme.spacing(1),
	},
	controlMargin: {
		margin: theme.spacing(1),
	},
	floatFab: {
		position: "absolute", 
		bottom: theme.spacing(3),
		right: theme.spacing(3),
	}
}))

export function useSearchQuery() {
	return new URLSearchParams(useLocation().search);
}

function TableSearchText({ label, icon, value, setValue, isValid, helperText }) {
	const classes = useStyles()

	if(!icon)
		icon = <SearchIcon />

	return (
		<TextField
			fullWidth
			className={classes.searchBox}
			label={label || "Cerca"}
			id="search-field"
			value={value}
			InputProps={{
				endAdornment: <InputAdornment position="end">{icon}</InputAdornment>
			}}
			aria-describedby="outlined-search-helper-text"
			inputProps={{ 'aria-label':'Ricerca' }}
			variant="outlined"
			onChange={e => setValue(e.target.value)}
			error={isValid===false}
			helperText={helperText}
			autoComplete="off"
		/>
	)
}

export function TableSearch({ name, setValue, valueFixer, ...props }) {
	const history = useHistory()
	const query = useSearchQuery()
	const location = useLocation()

	let value = query.get(name)
	if(!value)
		value = ''
	const updValue = (newValue) => {
		if(valueFixer)
			newValue = valueFixer(newValue)
		setValue?.(newValue)
		history.replace(location.pathname+'?'+name+'='+newValue)
	}

	//TODO per ora solo Text
	return <TableSearchText {...props} value={value} setValue={updValue} />
}

/*
function TabSearchText({ label, icon, value, setValue }) {
	return (
		<TextField
			fullWidth
			className={styles.searchBox}
			label={label}
			value={value}
			InputProps={{
				endAdornment: <InputAdornment position="end">{icon}</InputAdornment>
			}}
			variant="outlined"
			onChange={e => setValue(e.target.value)}
		/>
	)
}
*/

export function TableSearchSelect({ label, icon, value, setValue, options }) {
	const classes = useStyles()
	return (
		<FormControl variant="outlined" className={classes.searchBox} fullWidth>
			<InputLabel>{label}</InputLabel>
			<Select
				native
				value={value}
				onChange={e => setValue(e.target.value)}
				label={label}
			>
				{ options.map(opt => <option value={opt.value}>{opt.label}</option> )}
			</Select>
		</FormControl>
	)
}

/*
export function TabSearch({ label, icon, ...props }) {
	const styles = useStyles()

	if(!label)
		label = "Filtro di ricerca"
	if(!icon)
		icon = <SearchIcon />

	let input
	if(props.options)
		input = <TabSearchSelect label={label} icon={icon} {...props} />
	else
		input = <TabSearchText label={label} icon={icon} {...props} />

	return (
		<FormControl variant="outlined" className={styles.searchBox} fullWidth>
			{input}
		</FormControl>
	)
}
*/

export function TabControl({ label, icon, linkTo, onClick }) {
	const styles = useStyles()
	let btnProps = {
		variant:	'contained',
		color:		'primary',
		startIcon:	icon,
		className:	styles.controlMargin,
		onClick,
	}
	if(linkTo) {
		btnProps.component = {Link}
		btnProps.to = linkTo
	}
	return <Button {...btnProps}>{label}</Button>
}

export function TabFab({ linkTo, onClick, icon }) {
	const styles = useStyles()
	let btnProps = {
		color:		'primary',
		className:	styles.floatFab,
	}
	if(linkTo) {
		btnProps.component = Link
		btnProps.to = linkTo
	}
	if(!icon)
		icon = <AddIcon />
	return <Fab {...btnProps}>{icon}</Fab>
}

function Cell({ children, hide }) {
	const cell = <TableCell>{children}</TableCell>
	if(hide) {
		const hideProps = { [hide]:true }
		return <Hidden {...hideProps}>{cell}</Hidden>
	}
	else
		return cell
}

function Head({ def }) {
	const cols = def.map(col => (
		<Cell key={col.key} hide={col.hide}>{col.label}</Cell>
	))
	return (
		<TableHead>
			<TableRow>{cols}</TableRow>
		</TableHead>
	)
}

function useTsDisplay(ts, mode='auto') {
	const api = useContext(APICtx)
	const itemFix = num => num.toString().padStart(2, '0')

	if(!ts)
		return ''

	let date = new Date(ts*1000)
	let str = itemFix(date.getDate()) +
		'/' + itemFix(date.getMonth()+1) +
		'/' + itemFix(date.getFullYear())
	if((mode==='auto' && api.chkAcl(['admin'])) || mode===true)
		str += ' ' + itemFix(date.getHours()) +
			':' + itemFix(date.getMinutes()) +
			':' + itemFix(date.getSeconds())
	return str
}

function moneyDisplay(content) {
	return (content / 100).toFixed(2) + ' €'
}

function BodyRow({ def, children }) {
	const tsDisplay = useTsDisplay

	const cols = def.map(col => {
		let content
		if(col.content)
			content = col.content(children)
		else if(col.key==='_seq') {
			content = children._seqNum+'/'+children._seqYear
			if(children._seqRev && children._seqRev>0)
				content += ' rev'+children._seqRev
		}
		else if(col.key==='_assignee') {
			if(children._uAssignee)
				content = (
					<Grid container direction="column">
						<Grid item>{children._uAssigneeLabel}</Grid>
						{ children._tsAssignee && <Grid item>{tsDisplay(children._tsAssignee)}</Grid> }
					</Grid>
				)
		}
		else
			content = children[col.key]

		if(col.type==='DATE') {
			let reMatch
			if(typeof(content)==='string')
				reMatch = content.match(/^([0-9]{4})-([0-9]{2})-([0-9]{2})$/)

			if(reMatch)
				content = reMatch[3]+'/'+reMatch[2]+'/'+reMatch[1]
			else {
				let date = new Date(content*1000)
				content = date.getDate()+'/'+(date.getMonth()+1)+'/'+date.getFullYear()
			}
		}
		else if(col.type==='DATETIME')
			content = tsDisplay(content, true)
		else if(col.type==='MONEY')
			content = moneyDisplay(content)

		if(Array.isArray(content))
			content = content.map((contItem, contIdx) => <div key={contIdx}>{contItem}</div>)
		return (
			<Cell key={col.key} hide={col.hide}>
				{content}
			</Cell>
		)
	})
	return <TableRow>{cols}</TableRow>
}

function Pager({ page, setPage, count, pageSize }) {
	const onChangePage = (e, page) => {
		setPage(page)
	}

	return (
		<TablePagination
			component="div"
			count={count}
			rowsPerPage={pageSize}
			rowsPerPageOptions={[pageSize]}
			onChangePage={onChangePage}
			page={page}
		/>
	)
}

export function setTabScan(ret, setScan, page, setPage) {
	if(ret.count && ret.count>0 && ret.pageSize) {
		const pageMax = Math.ceil(ret.count/ret.pageSize) - 1
		if(page>pageMax)
			setPage(pageMax)
	}
	setScan(ret)
}

export default function Table({ def, children, page, setPage }) {
	const flagPager = ( children && children.count && children.pageSize )
	return (
		<>
		<TableContainer>
			<MuiTable>
				<Head def={def} />
				<TableBody>
					{ children.data.map(child => (
						<BodyRow key={child._id} def={def}>{child}</BodyRow>
					))}
				</TableBody>
			</MuiTable>
		</TableContainer>
		{ Boolean(flagPager) && <Pager page={page} setPage={setPage} count={parseInt(children.count)} pageSize={children.pageSize} /> }
		</>
	)
}
