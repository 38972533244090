import React, { useState, useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

import Router from './Router'

import APICtx, { useApi } from './lib/api'

const authTTL = 8000

export default function App() {
	const [ jwt, setJwt ] = useState(localStorage.getItem('jwt'))
	const [ auth, setAuth ] = useState()
	const api = useApi(jwt, setJwt, auth, setAuth)
	let jwtLast = null

	useEffect(() => {
		let timer
		function authRefresh() {
				api.call('auth/refresh', {}).then(ret => {
					if(ret) {
						if(jwtLast!==jwt) {
							if(ret.jwt) {
								localStorage.setItem('jwt', ret.jwt)
								setJwt(ret.jwt)
							}
						}
						setAuth(ret.auth)
					}
					timer = setTimeout(authRefresh, authTTL)
					jwtLast = jwt
				})
		}
		authRefresh()
		return () => clearTimeout(timer)

		// eslint-disable-next-line
	}, [ jwt ])

	if(auth===undefined)
		return (
			<Backdrop open={true}>
				<CircularProgress color="inherit" />
			</Backdrop>
		)
	else
		return (
			<APICtx.Provider value={api}>
				<Router />
			</APICtx.Provider>
		)
}
