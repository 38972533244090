import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import MotorcycleIcon from '@material-ui/icons/Motorcycle';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
import BuildIcon from '@material-ui/icons/Build';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import StoreIcon from '@material-ui/icons/Store';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import APICtx from '../lib/api'

const useStyles = makeStyles(theme => ({
	toolbar: theme.mixins.toolbar,
	imgLogo: {
		padding: "10px",
		width: "100%",
	},
}))

function MenuItem({ acl, to, icon, label }) {
	const api = useContext(APICtx)

	if(acl && !api.chkAcl(acl))
		return null
	else
		return (
			<ListItem button component={Link} to={to}>
				<ListItemIcon>{icon}</ListItemIcon>
				<ListItemText primary={label} />
			</ListItem>
		)
}

export default function MenuStaff() {
	const style = useStyles()

	return (
		<div>
			<div className={style.toolbar}>
				<img src="/logo.png" className={style.imgLogo} alt="Socar logo" />
			</div>
			<Divider />
			<List>
				<MenuItem acl="carrelliUser" to="/quotes" icon={<ListAltIcon />} label="Rich. preventivi" />
				{ /* <MenuItem acl="carrelliUser" to="/tires" icon={<MotorcycleIcon />} label="Contr. gomme" /> */ }
				<MenuItem acl="carrelliAdmin" to="/reqsupport" icon={<ContactSupportIcon />} label="Rich. assistenza" />
				<MenuItem acl={['!carrelliUser', '!paUser']} to="/reqsupport/edit" icon={<ContactSupportIcon />} label="Rich. assistenza" />
				<MenuItem acl="photoCarts" to="/photos/cart" icon={<PhotoLibraryIcon />} label="Foto carrelli" />
				<MenuItem acl="used" to="/used/list" icon={<StoreIcon />} label="Gestione usati" />
				<MenuItem acl="offers" to="/offers/list" icon={<LocalOfferIcon />} label="Gestione offerte" />
				<MenuItem acl="offers" to="/schede-tec/list" icon={<LocalShippingIcon />} label="Schede tecniche" />
			</List>
			<Divider />
			<List>
				<MenuItem acl="photoPa" to="/photos/pa" icon={<PhotoLibraryIcon />} label="Foto piattaforme" />
				<MenuItem acl="dev" to="/pa-preparation/list" icon={<BuildIcon />} label="Preparazioni" />
				<MenuItem acl="dev" to="/pa-preparation/stat" icon={<EqualizerIcon />} label="Statistiche Prep." />
			</List>
		</div>
	)
}