import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from "react-router-dom"
import ReCAPTCHA from "react-google-recaptcha";
import { v4 as uuidv4 } from 'uuid'

import APICtx from '../lib/api'
import Window from '../Window'
import Form, { FormCard, Input, InputTime, Select } from '../Form'
import { FormCart } from '../Socar'
import Attachments from '../Attachments'

const dataDefault = {
	addressCountry:	'IT',
}

export default function ReqSupportEdit() {
	const api = useContext(APICtx)
	const [ seq, setSeq ] = useState()
	const [ data, setData ] = useState(dataDefault)
	const [ attachmentsUuid, setAttachmentsUuid ] = useState(uuidv4())
	//const [ anagrLock, setAnagrLock ] = useState(false)
	const [ recapDone, setRecapDone ] = useState(false)
	const [ knownCustomer, setKnownCustomer ] = useState(false)
	const { _id } = useParams()
	const history = useHistory()

	const onSubmit = () => {
		if(!api.isAnon || recapDone)
			api.call('reqsupport/set', { _id, attachmentsUuid, ...data }).then(ret => {
				if(ret && ret._id) {
					if(api.isAnon)
						setData(dataDefault)
					else
						history.replace('/reqsupport/edit/'+ret._id)
				}
			})
		else
			api.msgError('Confermare il reCAPTCHA')
	}

	useEffect(() => {
		if(_id)
			api.call('reqsupport/get', { _id }).then(ret => {
				setKnownCustomer(null)
				setData(ret.data)
				setSeq(ret.data._seq)
			})
		// eslint-disable-next-line
	}, [])

	const customerVat = data.customerVat ? data.customerVat : null
	useEffect(() => {
		if(customerVat)
			api.call('anagr/getByVat', { vat:customerVat }).then(ret => {
				if(ret._id) {
					if(knownCustomer!==null) {
						setData(Object.assign({}, data, {
							customerName:		ret.data.name,
						}))
					}
					setKnownCustomer(true)
				}
				else
					setKnownCustomer(false)
			})
		else
			setKnownCustomer(false)
		// eslint-disable-next-line
	}, [customerVat])

	useEffect(() => {
		if(knownCustomer===false)
			setData({...data, itemAddressEqCustomer:false })
	}, [knownCustomer])

	return (
		<Window title="Mod. rich. assistenza" sidebar="staff">
			<Form onSubmit={onSubmit} btnLabel="Invia" data={data} setData={setData}>
				<FormCard xs={12} label="Richiesta">
					<Input name="customerRef" label="Nominativo persona richiedente" isRequired />
					<Input name="email" label="E-mail" isRequired />
					<Input name="phone" label="Telefono" />
				</FormCard>
				<FormCard xs={12} md={6} label="Azienda">
					<Input name="customerName" label="Denominazione" isDisabled={knownCustomer} />
					<Input name="customerVat" label="P. IVA" />
				</FormCard>
				<FormCard xs={12} md={6} label="Orari">
					<InputTime name="workMorningFrom" label="Mattina dalle" />
					<InputTime name="workMorningTo" label="Mattina alle" />
					<InputTime name="workAfternoonFrom" label="Pomeriggio dalle" />
					<InputTime name="workAfternoonTo" label="Pomeriggio alle" />
				</FormCard>
				<FormCart data={data} withAddress knownCustomer={knownCustomer} />
				<FormCard xs={12} md={6} label="Difetti o allarmi riscontrati">
					<Input name="description" label="Descrizione" multiline multilineSize={24} />
				</FormCard>
				<FormCard xs={12}>
					<Attachments uuid={attachmentsUuid} subjType="reqsupport" subjId={seq} />
				</FormCard>
				{ api.isAnon && (
					<FormCard xs={12}>
						<ReCAPTCHA
							sitekey="6Lf4hqAaAAAAAK-8tDTIARscqf2bBc1MRPxtCqD_"
							onChange={() => setRecapDone(true)}
						/>
					</FormCard>
				)}
			</Form>
		</Window>
	)
}
