import React, { useState, useCallback, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import { Link } from "react-router-dom"
import { useDropzone } from 'react-dropzone'

import { Paper, Grid, Typography, Avatar } from '@material-ui/core/'
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';

import IconDelete from '@material-ui/icons/Delete'
import CheckIcon from '@material-ui/icons/Check';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import CircularProgress from '@material-ui/core/CircularProgress';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PublishIcon from '@material-ui/icons/Publish';
//import PageviewIcon from '@material-ui/icons/Pageview';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import APICtx from './lib/api'
import BtnBlk from './Button'
import ImgZoom from './ImgZoom'

const useStyles = makeStyles(theme => ({
	title: {
		marginBottom: theme.spacing(2),
	},
	OLDblock: {
		//border: "1px solid black",
		marginBottom: theme.spacing(1),
		padding: theme.spacing(1),
	},
	OLDblockDZ: {
		//width: 300,
		//height: 200,
		marginBottom: theme.spacing(1),
		//"padding": "0 !important",
	},
	attachmentAvatar: {
		marginRight: theme.spacing(1),
	},
	attachmentLabel: {
		flexGrow: 1,
	},
	block: {
		width: 300,
	},
	blockMedia: {
		height: 200,
		cursor: "pointer",
	},
	blockDel: {
		marginLeft: "auto",
	},
	blockDZ: {
		height: 200,
		"padding": "0 !important",
	},
	dzIcon: {
		fontSize: 120,
		margin: "auto",
		color: "inherit",
	},
	OLDdzIcon: {
		fontSize: 120,
		margin: "auto",
		color: "inherit",
	},
	itemIcon: {
		flex: 1,
		display: "flex",
		"flex-direction": "column",
		margin: 0,
		width: "100%",
		height: "100%",
		color: "#888888",
		"align-items": "center",
	},
	dropzone: {
		flex: 1,
		display: "flex",
		"flex-direction": "column",
		//padding: "20px",
		//padding: "20px",
		margin: 0,
		width: "100%",
		height: "100%",
		color: "#888888",
		"align-items": "center",
		"border-width": "2px",
		"border-radius": "2px",
		"border-color": "#aaaaaa",
		"border-style": "dashed",
		"background-color": "#fafafa",
		outline: "none",
		//transition: "border .24s ease-in-out",
		cursor: "pointer",
	},
}))

const filePack = file => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader()
		reader.readAsDataURL(file)
		reader.onload = () => resolve({
			name: file.name,
			type: file.type,
			size: file.size,
			bin: reader.result,
		})
		reader.onerror = error => reject(error)
	})
}

/*
function DropzoneRow({ subjType, subjId, uuid, onlyImages, handleRefresh }) {
	const [ isUploading, setIsUploading ] = useState(false)
	const api = useContext(APICtx)
	const classes = useStyles()

	const onDrop = useCallback(acceptedFiles => {
		if(acceptedFiles.length) {
			setIsUploading(true)
			onUpload(acceptedFiles).then(() => setIsUploading(false))
		}
	}, [subjType, subjId, uuid])

	const onUpload = useCallback(async files => {
		const packed = await Promise.all(files.map(filePack))
		const data = {
			type: subjType,
			id: subjId,
			uuid,
			files: packed,
		}
		await api.call('attachments/upload', data)
		handleRefresh()
	}, [subjType, subjId, uuid])

	const dzOpts = { onDrop }
	if(onlyImages)
		dzOpts.accept = 'image/*'
	const { getRootProps, getInputProps, isDragActive } = useDropzone(dzOpts)

	let statusIcon = isDragActive ?
		<CheckIcon className={classes.dzIcon} /> :
		( isUploading ? 
			<CircularProgress className={classes.dzIcon} size="120px" />
			:
			( onlyImages ? <AddAPhotoIcon className={classes.dzIcon} /> : <PublishIcon className={classes.dzIcon} /> )
		)

	return (
		<Paper className={classes.blockDZ}>
			<div {...getRootProps({className:classes.dropzone})}>
				<input {...getInputProps()} />
				{statusIcon}
			</div>
		</Paper>
	)
}
*/

function DropzoneBlock({ subjType, subjId, uuid, onlyImages, handleRefresh }) {
	const [ isUploading, setIsUploading ] = useState(false)
	const api = useContext(APICtx)
	const classes = useStyles()

	const onDrop = useCallback(acceptedFiles => {
		if(acceptedFiles.length) {
			setIsUploading(true)
			onUpload(acceptedFiles).then(() => setIsUploading(false))
		}
	}, [subjType, subjId, uuid])

	const onUpload = useCallback(async files => {
		const packed = await Promise.all(files.map(filePack))
		const data = {
			type: subjType,
			id: subjId,
			uuid,
			files: packed,
		}
		await api.call('attachments/upload', data)
		handleRefresh()
	}, [subjType, subjId, uuid])

	const dzOpts = { onDrop }
	if(onlyImages)
		dzOpts.accept = 'image/*'
	const { getRootProps, getInputProps, isDragActive } = useDropzone(dzOpts)

	let statusIcon = isDragActive ?
		<CheckIcon className={classes.dzIcon} /> :
		( isUploading ? 
			<CircularProgress className={classes.dzIcon} size="120px" />
			:
			( onlyImages ? <AddAPhotoIcon className={classes.dzIcon} /> : <PublishIcon className={classes.dzIcon} /> )
		)

	return (
		<Grid item>
			<Card className={classes.block}>
				<CardContent className={classes.blockDZ}>
					<div {...getRootProps({className:classes.dropzone})}>
						<input {...getInputProps()} />
						{statusIcon}
					</div>
				</CardContent>
			</Card>
		</Grid>
	)
}

/*
function ItemRow({ id, subjType, uuid, fName, link, readOnly, handleRefresh }) {
	const api = useContext(APICtx)
	const classes = useStyles()

	const handleView = () => {
		window.open(link, '_blank').focus();
	}
	const handleDel = () => api.call('attachments/del', { fid:id, type:subjType, uuid }).then(handleRefresh)

	const btnDef = [{ icon:<PageviewIcon />, onClick:handleView }]
	if(!readOnly)
		btnDef.push({ icon:<IconDelete />, onClick:handleDel, confirmTxt:'Confermare la cancellazione?' })

	return (
		<Grid item>
			<Paper className={classes.block}>
				<Grid container alignItems="center">
					<Grid item>
						<Avatar className={classes.attachmentAvatar}>
							<InsertDriveFileIcon />
						</Avatar>
					</Grid>
					<Grid item className={classes.attachmentLabel}>
						<p>{fName}</p>
					</Grid>
					<Grid item>
						<BtnBlk def={btnDef} />
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	)
}
*/

function ItemBlock({ id, subjType, uuid, fName, fType, link, readOnly, handleRefresh, setZoom }) {
	const api = useContext(APICtx)
	const classes = useStyles()

	const isImage = fType.match("^image\/")

	const handleOpen = () => isImage ? setZoom(link) : window.open(link, '_blank').focus()
	const handleDel = () => api.call('attachments/del', { fid:id, type:subjType, uuid }).then(handleRefresh)
	const btnDef = []
	if(!readOnly)
		btnDef.push({ icon:<IconDelete />, onClick:handleDel, confirmTxt:'Confermare la cancellazione?' })

	return (
		<Grid item>
			<Card className={classes.block}>
				<CardMedia className={classes.blockMedia} image={isImage && link} onClick={handleOpen}>
					{ !isImage && (
						<div className={classes.itemIcon}>
							<InsertDriveFileIcon className={classes.dzIcon} />
						</div>
					)}
				</CardMedia>
				<CardActions disableSpacing>
					<Typography variant="body2">{fName}</Typography>
					<BtnBlk def={btnDef} className={classes.blockDel} />
				</CardActions>
			</Card>
		</Grid>
	)
}

export default function Attachments({ uuid, subjType, subjId, max, onlyImages, readOnly }) {
	const [ files, setFiles ] = useState([])
	const [ imgZoomAddr, setImgZoomAddr ] = useState()
	const api = useContext(APICtx)
	const classes = useStyles()

	const handleZoomClose = () => setImgZoomAddr(null)
	const handleRefresh = () => api.call('attachments/scan', { type:subjType, id:subjId, uuid }).then(ret => setFiles(ret.list))
	useEffect(handleRefresh, [ subjType, subjId, uuid ])
	const handleDownload = e => api.doc('attachments/zip', { type:subjType, id:subjId, uuid })

	const uploadActive = !readOnly && (!max || files.length<max)
	/*
	return (
		<Grid container direction="column">
			<Grid item>
				<Typography variant="h5">Allegati</Typography>
			</Grid>
			{ files.map(file => <ItemRow key={file.id} {...file} subjType={subjType} readOnly={readOnly} handleRefresh={handleRefresh} /> )}
			{ uploadActive && <DropzoneRow subjType={subjType} subjId={subjId} uuid={uuid} onlyImages={onlyImages} handleRefresh={handleRefresh} /> }
		</Grid>
	)
	*/
	return (
		<>
			<Typography variant="h5" className={classes.title}>
				Allegati
				<IconButton color="primary" aria-label="upload picture" component="span" onClick={handleDownload}>
					<CloudDownloadIcon />
				</IconButton>
			</Typography>
			<Grid container justify="center" alignItems="stretch" spacing={2}>
				{ files.map(file => (
					<ItemBlock
						key={file.id}
						{...file}
						subjType={subjType}
						readOnly={readOnly}
						handleRefresh={handleRefresh}
						setZoom={setImgZoomAddr}
					/>
				))}
				{ uploadActive && (
					<DropzoneBlock
						subjType={subjType}
						subjId={subjId}
						uuid={uuid}
						onlyImages={onlyImages}
						handleRefresh={handleRefresh}
					/>
				)}
			</Grid>
			<ImgZoom label="Dettaglio foto" addr={imgZoomAddr} onClose={handleZoomClose} />
		</>
	)
}