import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams } from "react-router-dom"

import CameraAltIcon from '@material-ui/icons/CameraAlt';
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';
import HistoryIcon from '@material-ui/icons/History';

import APICtx from '../lib/api'
import Window from '../Window'
import Button from '../Button'
import BtnCSV from '../BtnCSV'
import Table, { setTabScan, TableSearch, useSearchQuery } from '../Table'

const regNumberFix = (type, rn) => {
	if(type==='cart' && rn) {
		let tmp = rn.replace(/\./g, '')
		rn = ''
		const cpLength = Math.min(tmp.length, 8)
		for(let i=0; i<cpLength; i++) {
			if(rn.length===2 || rn.length===5)
				rn += '.'
			if(tmp[i].match(/^[0-9]$/))
				rn += tmp[i]
		}
	}
	return rn
}

const validateRN = (type, rn, scanCount, setIsValid, setSrcHelper) => {
	let valid = null
	let helper
	if(type==='cart') {
		helper = []
		if(rn && rn.length>=2) switch(rn.slice(0, 2)) {
			case '10':
				helper.push('(10) Carrelli elevatori frontali')
			break
			case '11':
				helper.push('(11) Carrelli da terzi (CDT)')
			break
			case '12':
				helper.push('(12) Magazzinieri')
			break
			case '13':
				helper.push('(13) Carrelli usati')
			break
			case '14':
				helper.push('(14) Attrezzature/Caricabatterie')
			break
			default:
				valid = false
		}

		if(valid!==false && rn && rn?.length===10)
			valid = true
		else if(rn) {
			valid = false
			helper.push('Richiesto formato TT.XX.XXXX con TT validi: 10, 11, 12, 13, 14')
		}
		helper = helper.join(' - ')
	}
	else if(type==='pa' && rn && rn?.length>=3)
		valid = true

	//if(valid && scanCount)
	//	valid = null

	setIsValid(valid)
	setSrcHelper(helper)
}

export default function PhotoList() {
	const api = useContext(APICtx)
	const query = useSearchQuery()
	const history = useHistory()
	const [ scan, setScan ] = useState({ data:[], count:0 })
	const [ page, setPage ] = useState(0)
	const [ isValid, setIsValid ] = useState(null)
	const [ srcHelper, setSrcHelper ] = useState(null)
	const { type='pa' } = useParams()
	const regNumber = query.get('regNumber')

	const handleRefresh = () => api.call('photos/scan', { type, regNumber, page }).then(ret => setTabScan(ret, setScan, page, setPage))
	// eslint-disable-next-line
	useEffect(handleRefresh, [ type, regNumber, page ])
	useEffect(() => validateRN(type, regNumber, scan?.count, setIsValid, setSrcHelper), [ type, regNumber, scan?.count ])

	const handleEdit = ({ regNumber }) => history.push('/photos/'+type+'/'+regNumber)
	const handleHistory = ({ regNumber }) => history.push('/photos/'+type+'/'+regNumber+'/history')

	const btnDef = [
		{ icon:<CameraAltIcon />, onClick:handleEdit, tooltip:"Gestione foto" },
		{ icon:<HistoryIcon />, onClick:handleHistory, tooltip:"Archivio esteso" },
	]

	const tabDef = [
		{ key:'regNumber', label:'Num. macchina' },
		{ key:'dateLast', label:'Data ultimo caricamento', type:'DATE' },
		{ key:'authorLast', label:'Autore ultimo caricamento', hide:'xsDown' },
		{ key:'dateQty', label:'Quantità totale caricamenti' },
		{ key:'_btns', content:row => (
			<Button row={row} def={btnDef} />
		)},
	]

	const fabDef = (regNumber && isValid) ? { icon:<CameraAltIcon />, linkTo:'/photos/'+type+'/'+regNumber } : null

	const itemName = type==='cart' ? 'carrelli' : 'piattaforme'
	return (
		<Window title={"Foto "+itemName} sidebar="staff" fab={fabDef}>
			<TableSearch name="regNumber" isValid={isValid} helperText={srcHelper} valueFixer={val => regNumberFix(type, val)} label="Seleziona numero macchina" icon={<SpeakerNotesIcon />} />
			<BtnCSV tag="photos/csv" filters={{ type, srcHelper }} />
			<Table def={tabDef} page={page} setPage={setPage}>{scan}</Table> 
		</Window>
	)
}