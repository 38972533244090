import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from "react-router-dom"
import { v4 as uuidv4 } from 'uuid'

import APICtx from '../lib/api'
import Window from '../Window'
import Form, { FormCard, Input, Radios } from '../Form'
//import { FormCart } from '../Socar'
//import { Typography } from '@material-ui/core';
import Attachments from '../Attachments'

const types = [
	{ label:'Frontali diesel', value:'fdiesel' },
	{ label:'Frontali elettrici', value:'felectric' },
	{ label:'Magazzino', value:'warehouse' },
	{ label:'Piattaforme aeree', value:'aerialp' },
]

export default function UsedEdit() {
	const api = useContext(APICtx)
	const [ seq, setSeq ] = useState()
	const [ data, setData ] = useState({})
	const [ attachmentsUuid, setAttachmentsUuid ] = useState(uuidv4())
	//const [ anagrLock, setAnagrLock ] = useState(false)
	const { _id } = useParams()
	const history = useHistory()

	const onSubmit = () => {
		api.call('used/set', { _id, attachmentsUuid, ...data }).then(ret => {
			if(ret && ret._id)
				history.replace('/used/edit/'+ret._id)
		})
	}

	useEffect(() => {
		if(_id)
			api.call('used/get', { _id }).then(ret => {
				//setAnagrLock(null)
				setData(ret.data)
				setSeq(ret.data._seq)
			})
		// eslint-disable-next-line
	}, [])

	/*const customerVat = data.customerVat ? data.customerVat : null
	useEffect(() => {
		if(customerVat)
			api.call('anagr/getByVat', { vat:customerVat }).then(ret => {
				if(ret._id) {
					if(anagrLock!==null)
						setData(Object.assign({}, data, {
							customerName:		ret.data.name,
							customerTaxCode:	ret.data.taxCode,
							addressTxt:		ret.data.addressTxt,
							addressLoc:		ret.data.addressLoc,
							addressProv:		ret.data.addressProv,
							addressZip:		ret.data.addressZip,
							addressCountry:		ret.data.addressCountry,
							phone:			ret.data.phone,
							email:			ret.data.email,
						}))
					setAnagrLock(true)
				}
				else
					setAnagrLock(false)
			})
		else
			setAnagrLock(false)
		// eslint-disable-next-line
	}, [customerVat])
	*/

	/*
	const sparepartsDef = [
		{
			key:	'qty',
			label:	'Quantità',
		},
		{
			key:	'code',
			label:	'Codice',
		},
		{
			key:	'description',
			label:	'Descrizione',
			fullWidth:	true,
		},
	]
	*/

	return (
		<Window title="Scheda usato" sidebar="staff">
			<Form onSubmit={onSubmit} btnLabel="Salva" data={data} setData={setData}>
				<FormCard xs={12}>
					<Radios name="type" label="Divisione" isRequired def={types} />
				</FormCard>
				<FormCard xs={12}>
					{/* <Input name="subtype" label="" /> */}
					<Input name="brand" label="Marca" isRequired />
					<Input name="model" label="Modello" isRequired />
					<Input name="serial" label="Matricola" isRequired />
					<Input name="directory" label="Cartella" isRequired />
					<Input name="capacity" label="Portata" />
					<Input name="elevation" label="Elevazione (mm)" />
					<Input name="wrkheight" label="Altezza di lavoro" />
					<Input name="tires" label="Gommatura" />
					<Input name="forks" label="Forche (mm)" />
					<Input name="year" label="Anno" />
					<Input name="equipment" label="Equipaggiamento" />
					<Input name="weight" label="Peso" />
					<Input name="category" label="Categoria" />
					<Input name="notes" label="Note" />
				</FormCard>

				<FormCard xs={12}>
					<Attachments uuid={attachmentsUuid} subjType="used" subjId={seq} />
				</FormCard>
			</Form>
		</Window>
	)
}
