import React, { useState, useContext } from 'react';
import { useParams } from "react-router-dom"

import { v4 as uuidv4 } from 'uuid'

import Window from '../Window'
import APICtx from '../lib/api'
import Form, { FormCard, Input, Password } from '../Form'
import ABox from '../Attachments'

export default function Attachments() {
	const api = useContext(APICtx)
	const [ attachmentsUuid, setAttachmentsUuid ] = useState(uuidv4())
	const { type, id } = useParams()
	
	return (
		<Window title="Allegati">
			<ABox uuid={attachmentsUuid} subjType={type} subjId={id} readOnly />
		</Window>
	)
}
