import React, { useEffect, useContext } from 'react';
import { useParams } from "react-router-dom"

import APICtx from '../lib/api'

async function execPrint(api, _id) {
	const ret = await api.call('quote/get', { _id })
	if(_id && !ret.data._uAssignee && api.chkAcl(['write']) && api.chkAcl(['carrelliAdmin']))
		await api.call('quote/assign', { _id })
	const url = await api.doc('quote/pdf', {_id}, false)
	window.location = url
}

export default function QuotesPrint() {
	const api = useContext(APICtx)
	const { _id } = useParams()

	useEffect(() => {
		_id && execPrint(api, _id).then()
		// eslint-disable-next-line
	}, [])

	return null
}
