import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from "react-router-dom"
import Button from '@material-ui/core/Button';

import APICtx from '../lib/api'
import Window from '../Window'
import Form, { FormCard, Input, Radios, InputDate } from '../Form'

const types = [
	{ label:'Vendita nuovo', value:'saleNew' },
	{ label:'Vendita usato', value:'saleUsed' },
	{ label:'Noleggio', value:'rent' },
]

const periodTypes = [
	{ label:'Giorno', value:'giorno' },
	{ label:'Mese', value:'mese' },
]

const transportPrices = [
	{ label:'Da definire', value:null },
	{ label:'Fino a 10Km: €100,00', value:'100' },
	{ label:'Da 11Km a 30Km: €200,00', value:'200' },
	{ label:'Cremona/Bergamo: €300,00', value:'300' },
	{ label:'Da 31Km a 40Km: €320,00', value:'320' },
	{ label:'Da 41Km a 60Km: €360,00', value:'360' },
	{ label:'Da 61Km a 80Km: €440,00', value:'440' },
	{ label:'Da 81Km a 100Km: €550,00', value:'550' },
]

export default function OffersEdit() {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({ priceTransport:null, period:'giorno' })
	const [ prodCats, setProdCats ] = useState([])
	const [ prodItems, setProdItems ] = useState([])
	const { _id } = useParams()
	const history = useHistory()

	const onSubmit = () => {
		api.call('offers/set', { _id, ...data }).then(ret => {
			if(ret && ret._id)
				history.replace('/offers/edit/'+ret._id)
		})
	}

	const btnPdf = () => prodItems
		.filter(({ _id }) => data?.product===_id)
		.every(({ pdfUrl }) => window.open(pdfUrl, '_blank'))

	const prodCatsOpts = prodCats.map(cat => ({ value:cat, label:cat }))
	const prodItemsOpts = prodItems.map(({ _id, name }) => ({ value:_id, label:name }))
	const prodCur = prodItems.filter(({ _id }) => data?.product===_id)

	const refreshProdCats = () => api.call('offersprods/cats', { hWorking:data?.hWorking }).then(ret => setProdCats(ret.data))
	const refreshProdItems = () => data?.productCat ?
		api.call('offersprods/scan', { category:data?.productCat, hWorking:data?.hWorking }).then(ret => setProdItems(ret.data)) : []
	const autocompleteEmail = string => api.call('offers/autocompleteEmail', { string })

	useEffect(() => {
		if(_id)
			api.call('offers/get', { _id }).then(async ret => {
				setData({
					...ret.data,
					priceTransport:(ret.data.priceTransport && ret.data.priceTransport.toString())
				})
				await refreshProdCats()
				await refreshProdItems()
			})
		else
			refreshProdCats()
		// eslint-disable-next-line
	}, [])
	// eslint-disable-next-line
	useEffect(refreshProdCats, [ data.hWorking ])
	// eslint-disable-next-line
	useEffect(refreshProdItems, [ prodCats, data.productCat, data.hWorking ])

	return (
		<Window title="Offerta" sidebar="staff">
			<Form onSubmit={onSubmit} btnLabel="Salva" data={data} setData={setData}>
				<FormCard xs={12}>
					<Input name="destEmail" label="Email destinatario" isRequired autocomplete={autocompleteEmail} />
					<Input name="destName" label="Ragione sociale" isRequired />
					<Radios name="type" label="Tipologia offerta" isRequired def={types} />
				</FormCard>
				<FormCard xs={12}>
					<Input name="hWorking" label="Altezza di lavoro (m)" />
					<Radios name="productCat" label="Categoria" isRequired def={prodCatsOpts} />
					{ Boolean(data.productCat) && <Radios name="product" label="Prodotto" isRequired def={prodItemsOpts} /> }
					{ Boolean(prodCur.length) && prodCur[0].pdfUrl && (
						<Button variant="contained" color="primary" onClick={btnPdf}>
							Visualizza scheda prodotto
						</Button>
					)}
				</FormCard>
				<FormCard xs={12}>
					<Input name="price" label="Prezzo" isRequired />
					{ data.type==='rent' && <Radios name="period" label="Canone" isRequired def={periodTypes} /> }
					{ prodCur[0]?.requireTransport && (
						<>
							<Radios name="priceTransport" label="Prezzo trasporto" isRequired def={transportPrices} />
							<Input name="locTransport" label="Destinazione trasporto" />
						</>
					)}
					{ data.type==='saleUsed' && <Input name="intReference" label="Riferimenti interni" /> }
				</FormCard>
			</Form>
		</Window>
	)
}
