import React, { useContext } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'

import Login from './page/Login'
import Attachments from './page/Attachments'
import QuotesList from './page/QuotesList'
import QuotesEdit from './page/QuotesEdit'
import QuotesPrint from './page/QuotesPrint'
import TiresList from './page/TiresList'
import TiresEdit from './page/TiresEdit'
import ReqSupportList from './page/ReqSupportList'
import ReqSupportEdit from './page/ReqSupportEdit'
import ReqSupportPrint from './page/ReqSupportPrint'
//import PAPhotoList from './page/PAPhotoList'
//import PAPhotoItem from './page/PAPhotoItem'
import PAPrepList from './page/PAPrepList'
import PAPrepArrival from './page/PAPrepArrival'
import PAPrepDone from './page/PAPrepDone'
//import PAPrepDelivery from './page/PAPrepDelivery'
import PhotoList from './page/PhotoList'
import PhotoItem from './page/PhotoItem'
import PhotoHistory from './page/PhotoHistory'
import PhotoHistoryDate from './page/PhotoHistoryDate'
import UsedList from './page/UsedList'
import UsedEdit from './page/UsedEdit'
import OffersProducts from './page/OffersProducts'
import OffersList from './page/OffersList'
import OffersEdit from './page/OffersEdit'

import APICtx from './lib/api'

function RouteUser({ children, location, ...props }) {
	const api = useContext(APICtx)
	if(!api.isAnon)
		return <Route {...props}>{children}</Route>
	else {
		return (
			<Route {...props}>
				<Redirect to={{
					pathname: "/login",
					state: { referrer: location }
				}} />
			</Route>
		)
	}
}

export default function Router({ summary }) {
	const api = useContext(APICtx)
	let defaultPage = api.chkAcl('carrelliUser') ? <QuotesList /> : (api.chkAcl('paUser') ? <PhotoList /> : <ReqSupportEdit />)

	return (
		<BrowserRouter>
			<Switch>
				<Route path='/login'>
					<Login />
				</Route>
				<RouteUser path='/attachments/:type/:id'>
					<Attachments />
				</RouteUser>

				<RouteUser path='/quotes/print/:_id'>
					<QuotesPrint />
				</RouteUser>
				<RouteUser path='/quotes/edit/:_id'>
					<QuotesEdit />
				</RouteUser>
				<RouteUser path='/quotes/edit'>
					<QuotesEdit />
				</RouteUser>
				<RouteUser path='/quotes'>
					<QuotesList />
				</RouteUser>

				<RouteUser path='/tires/edit/:_id'>
					<TiresEdit />
				</RouteUser>
				<RouteUser path='/tires/edit'>
					<TiresEdit />
				</RouteUser>
				<RouteUser path='/tires'>
					<TiresList />
				</RouteUser>

				<RouteUser path='/reqsupport/print/:_id'>
					<ReqSupportPrint />
				</RouteUser>
				<RouteUser path='/reqsupport/edit/:_id'>
					<ReqSupportEdit />
				</RouteUser>
				<Route path='/reqsupport/edit'>
					<ReqSupportEdit />
				</Route>
				<RouteUser path='/reqsupport'>
					<ReqSupportList />
				</RouteUser>

				<RouteUser path='/used/edit/:_id'>
					<UsedEdit />
				</RouteUser>
				<Route path='/used/edit'>
					<UsedEdit />
				</Route>
				<RouteUser path='/used/list'>
					<UsedList />
				</RouteUser>

				<RouteUser path='/schede-tec/list'>
					<OffersProducts />
				</RouteUser>

				<RouteUser path='/offers/edit/:_id'>
					<OffersEdit />
				</RouteUser>
				<Route path='/offers/edit'>
					<OffersEdit />
				</Route>
				<RouteUser path='/offers/list'>
					<OffersList />
				</RouteUser>

{ /*
				<RouteUser path='/pa-photo/:regNumber'>
					<PAPhotoItem />
				</RouteUser>
				<RouteUser path='/pa-photo'>
					<PAPhotoList />
				</RouteUser>
*/ }
				<RouteUser path='/photos/:type/:regNumber/history/:date'>
					<PhotoHistoryDate />
				</RouteUser>
				<RouteUser path='/photos/:type/:regNumber/history'>
					<PhotoHistory />
				</RouteUser>
				<RouteUser path='/photos/:type/:regNumber'>
					<PhotoItem />
				</RouteUser>
				<RouteUser path='/photos/:type'>
					<PhotoList />
				</RouteUser>

				<RouteUser path='/pa-preparation/arrival/:_id'>
					<PAPrepArrival />
				</RouteUser>
				<RouteUser path='/pa-preparation/arrival'>
					<PAPrepArrival />
				</RouteUser>
				<RouteUser path='/pa-preparation/done/:_id'>
					<PAPrepDone />
				</RouteUser>
				<RouteUser path='/pa-preparation/done'>
					<PAPrepDone />
				</RouteUser>
				{ /*
				<RouteUser path='/pa-preparation/delivery/:_id'>
					<PAPrepDelivery />
				</RouteUser>
				<RouteUser path='/pa-preparation/delivery'>
					<PAPrepDelivery />
				</RouteUser>
				*/ }
				<RouteUser path='/pa-preparation/stat'>
					<PAPrepList />
				</RouteUser>
				<RouteUser path='/pa-preparation/list'>
					<PAPrepList />
				</RouteUser>

				<Route path='/'>
					{defaultPage}
				</Route>
			</Switch>
		</BrowserRouter>
	)
}
