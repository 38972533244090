import React, { useState, useEffect, useContext } from 'react'

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';

import APICtx from '../lib/api'
import Window from '../Window'
import Button from '../Button'
import Table, { setTabScan, TableSearch } from '../Table'

export default function UsedList() {
	const api = useContext(APICtx)
	const [ scan, setScan ] = useState({ data:[], count:0 })
	const [ page, setPage ] = useState(0)
	const [ filterStr, setFilterStr ] = useState('')
	
	const handleRefresh = () => api.call('offersprods/scan', { page, filterStr }).then(ret => setTabScan(ret, setScan, page, setPage))
	// eslint-disable-next-line
	useEffect(handleRefresh, [ page, filterStr ])

	const btnPdf = ({ pdfUrl }) => window.open(pdfUrl, '_blank')

	const btnDefPdf = { icon:<InsertDriveFileIcon />, onClick:btnPdf, tooltip:"Apri PDF" }

	const tabDef = [
		{ key:'category', label:'Categoria' },
		{ key:'name', label:'Nome' },
		{ key:'_btns', content:row => {
			return row.pdfUrl ? <Button row={row} def={[btnDefPdf]} /> : ''
		}},
	]

	return (
		<Window title="Schede prodotti" sidebar="staff">
			<TableSearch name="str" label="Cerca" setValue={setFilterStr} />
			<Table def={tabDef} page={page} setPage={setPage}>{scan}</Table> 
		</Window>
	)
}