import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from "react-router-dom"
import { v4 as uuidv4 } from 'uuid'

import APICtx from '../lib/api'
import Window from '../Window'
import Form, { FormCard, Input, InputTab, Checkbox, Radios } from '../Form'
import { FormCart } from '../Socar'
import { Typography } from '@material-ui/core';
import Attachments from '../Attachments'

function SubTires({ label, prefix }) {
	const p = 'tires'+prefix
	return (
		<FormCard xs={12} md={6} label={"Gomme "+label}>
			<Input name={p+"Size"} label="Misura" />
			<Input name={p+"DiamInt"} type="number" label="Diametro interno (mm)" />
			<Input name={p+"HolesQty"} type="number" label="Numero fori" />
			<Input name={p+"HolesDiam"} type="number" label="Diametro fori (mm)" />
			<Input name={p+"Flangia"} type="number" label="Spostamento flangia (mm)" />
			<Typography>Si riscontrano rotture, criccature oppure ovalizzazioni fori ai cerchi?</Typography>
			<Checkbox name={p+"IssuesL"} label="Cerchio sinistro" />
			<Checkbox name={p+"IssuesR"} label="Cerchio destro" />
		</FormCard>
	)
}

export default function QuotesEdit() {
	const api = useContext(APICtx)
	const [ seq, setSeq ] = useState()
	const [ data, setData ] = useState({
		addressCountry:	'IT',
		officeProv: api.authProvDefault(),
	})
	const [ attachmentsUuid, setAttachmentsUuid ] = useState(uuidv4())
	const [ anagrLock, setAnagrLock ] = useState(false)
	const { _id } = useParams()
	const history = useHistory()

	const onSubmit = () => {
		api.call('quote/set', { _id, attachmentsUuid, ...data }).then(ret => {
			if(ret && ret._id)
				history.replace('/quotes/edit/'+ret._id)
		})
	}

	useEffect(() => {
		if(_id)
			api.call('quote/get', { _id }).then(ret => {
				setAnagrLock(null)
				setData(ret.data)
				setSeq(ret.data._seq)
			})
		// eslint-disable-next-line
	}, [])

	const customerVat = data.customerVat ? data.customerVat : null
	useEffect(() => {
		if(customerVat)
			api.call('anagr/getByVat', { vat:customerVat }).then(ret => {
				if(ret._id) {
					if(anagrLock!==null)
						setData(Object.assign({}, data, {
							customerName:		ret.data.name,
							customerTaxCode:	ret.data.taxCode,
							addressTxt:		ret.data.addressTxt,
							addressLoc:		ret.data.addressLoc,
							addressProv:		ret.data.addressProv,
							addressZip:		ret.data.addressZip,
							addressCountry:		ret.data.addressCountry,
							phone:			ret.data.phone,
							email:			ret.data.email,
						}))
					setAnagrLock(true)
				}
				else
					setAnagrLock(false)
			})
		else
			setAnagrLock(false)
		// eslint-disable-next-line
	}, [customerVat])

	const sparepartsDef = [
		{
			key:	'qty',
			label:	'Quantità',
		},
		{
			key:	'code',
			label:	'Codice',
		},
		{
			key:	'description',
			label:	'Descrizione',
			fullWidth:	true,
		},
	]

	return (
		<Window title="Rich. preventivo" sidebar="staff">
			<Form onSubmit={onSubmit} btnLabel="Salva" data={data} setData={setData}>
				<FormCard xs={12}>
					<Input name="number" label="Commessa" />
				</FormCard>
				<FormCard xs={12} md={6} label="Cliente">
					<Input name="customerName" label="Denominazione" isRequired isDisabled={anagrLock} />
					<Input name="customerIncharge" label="Persona di riferimento" isRequired />
					<Input name="addressLoc" label="Località" isRequired />
					<Radios name="officeProv" label="Sede assegnata" isRequired def={[
						{ value:"BS", label:"BS" },
						{ value:"CR", label:"CR" },
					]} />
				</FormCard>
				<FormCart />
				<FormCard xs={12} md={6} label="Batteria trazione">
					<Input name="batteryBrand" label="Marca" />
					<Input name="batteryVoltage" label="Tensione" />
					<Input name="batteryCapacity" label="Capacità" />
				</FormCard>
				<FormCard xs={12} md={6} label="Montante di sollevamento">
					<Radios name="montanteType" label="Tipologia" def={[
						{ value:"sx", label:"SX" },
						{ value:"dx", label:"DX" },
						{ value:"tx", label:"TX" },
					]} />
					<Input name="montanteElevation" label="Elevazione (mm)" />
				</FormCard>
				<SubTires prefix="A" label="anteriori" />
				<SubTires prefix="P" label="posteriori" />
				{ /*
				<FormCard xs={12} md={6} label="Gomme">
					<Input name="tiresASize" label="Anteriori misura" />
					<Input name="tiresPSize" label="Posteriori misura" />
				</FormCard>
				*/ }
				<FormCard xs={12} md={6} label="Attrezzatura">
					<Checkbox name="equipDSL" label="DSL" />
					{ data.equipDSL ? (
						<>
							<Input name="equipDSLModel" label="Modello" />
							<Input name="equipDSLNumber" label="Matricola" />
							<Input name="equipDSLMaker" label="Costruttore" />
							<Input name="equipDSLYear" label="Anno" />
						</>
					) : null}
					<Checkbox name="equipRotante" label="Rotante" />
					{ data.equipRotante ? (
						<>
							<Input name="equipRotanteModel" label="Modello" />
							<Input name="equipRotanteNumber" label="Matricola" />
							<Input name="equipRotanteMaker" label="Costruttore" />
							<Input name="equipRotanteYear" label="Anno" />
						</>
					) : null}
					<Checkbox name="equipPinza" label="Pinza" />
					{ data.equipPinza ? (
						<>
							<Input name="equipPinzaModel" label="Modello" />
							<Input name="equipPinzaNumber" label="Matricola" />
							<Input name="equipPinzaMaker" label="Costruttore" />
							<Input name="equipPinzaYear" label="Anno" />
						</>
					) : null}
					<Checkbox name="equipRegForche" label="Reg. Forche" />
					{ data.equipRegForche ? (
						<>
							<Input name="equipRegForcheModel" label="Modello" />
							<Input name="equipRegForcheNumber" label="Matricola" />
							<Input name="equipRegForcheMaker" label="Costruttore" />
							<Input name="equipRegForcheYear" label="Anno" />
						</>
					) : null}
				</FormCard>
				
				<FormCard xs={12} label="Ricambi necessari">
					<InputTab name="spareparts" def={sparepartsDef} />
				</FormCard>

				<FormCard xs={12} label="Lavori da eseguire">
					<Input name="hoursEstimated" label="Ore previste lavoro" isRequired />
					<Input name="fixTodo" label="Descrizione" multiline multilineSize={16} />
				</FormCard>

				<FormCard xs={12}>
					<Attachments uuid={attachmentsUuid} subjType="quotes" subjId={seq} />
				</FormCard>
			</Form>
		</Window>
	)
}
