import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from "react-router-dom"

import APICtx from '../lib/api'
import Window from '../Window'
import Form, { FormCard, Input, InputDate, InputTime, Radios } from '../Form'

export default function PAPrepDone() {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({})
	const { _id } = useParams()
	const history = useHistory()
	const regNumber = data.regNumber ? data.regNumber : null

	const onSubmit = () => {
		api.call('piattaforma/setPrep', { _id, ...data }).then(ret => {
			if(ret && ret._id)
				history.replace('/pa-preparation/edit/'+ret._id)
		})
	}

	useEffect(() => {
		if(_id)
			api.call('piattaforma/getPrep', { _id }).then(ret => {
				setData(ret.data)
			})
		// eslint-disable-next-line
	}, [])

	useEffect(() => {
		if(regNumber)
			api.call('piattaforma/getByRegNumber', { regNumber }).then(ret => {
				/* TODO
				setData(Object.assign({}, data, {
					customerName:		ret.data.name,
					customerTaxCode:	ret.data.taxCode,
				}))
				*/
			})
		// eslint-disable-next-line
	}, [regNumber])

	const X_id = 123

	return (
		<Window title="Modifica preparazione" sidebar="staff">
			<Form onSubmit={onSubmit} btnLabel="Salva" data={data} setData={setData}>
				<FormCard xs={12} md={6} label="Piattaforma aerea">
					<Input name="regNumber" label="Numero" isRequired />
					<Input name="paModel" label="Modello" isDisabled />
					<Input name="paType" label="Tipologia" isDisabled />
				</FormCard>
				<FormCard xs={12} md={6} label="">
					{ X_id && <InputDate name="date" label="Data registrazione" isDisabled /> }
					{ X_id && <InputTime name="time" label="Ora registrazione" isDisabled /> }
					<Input name="workingHours" label="Lettura ore di lavoro" type="number" isRequired />
					<Radios name="type" label="Tipologia preparazione" isRequired def={[
						{ value:"standard", label:"Standard" },
						{ value:"ctrl-trimestrale", label:"Controllo trimestrale" },
						{ value:"ctrl-semestrale", label:"Controllo semestrale" },
						{ value:"tagliando", label:"Tagliando" },
					]} />
				</FormCard>
				<FormCard xs={12} label="">
					<Input name="notes" label="Note" multiline multilineSize={8} />
				</FormCard>
			</Form>
		</Window>
	)
}
