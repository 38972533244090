import React from "react"
import { useSnackbar } from 'notistack'
import superagent from "superagent"

const APICtx = React.createContext(null)
export default APICtx

const urlApi = 'https://assistenza.socar.it/api.php'

function apiCall(jwt, tag, p, files) {
	let req = superagent
			.post(urlApi)
			.accept('application/json')
			.field('tag', tag)
	if(p)
		req.field('p', JSON.stringify(p))
	if(jwt)
		req.set('Authorization', 'Bearer '+jwt)
	if(files)
		for(let file of files)
			req.attach(file.name, file)
	return req.then(res => res.body)
}

export function useApi(jwt, setJwt, auth, setAuth) {
	const { enqueueSnackbar } = useSnackbar()
	const authAcls = (auth && auth.acls && Array.isArray(auth.acls)) ? auth.acls : []

	const chkAcl = (aclList) => {
		if(!Array.isArray(aclList))
			aclList = [aclList]
		for(let acl of aclList) {
			if(acl[0]==='!' && authAcls.includes(acl.substring(1)))
				return false
			else if(acl[0]!=='!' && !authAcls.includes(acl))
				return false
		}
		return true
	}

	const api = {
		call: (tag, p, files) => {
			return apiCall(jwt, tag, p, files).then(res => {
				//console.log('API-ret', res)
				if(res.dbgs)
					res.dbgs.forEach(txt => console.log('API-DBG ['+tag+']', txt))
				if(res.errs)
					res.errs.forEach(txt => enqueueSnackbar(txt, { variant:'error' }))
				if(res.oks)
					res.oks.forEach(txt => enqueueSnackbar(txt, { variant:'success' }))
				if(res.infos)
					res.infos.forEach(txt => enqueueSnackbar(txt, { variant:'info' }))
				if(res._stat)
					return res.data ? res.data : {}
			})
		},
		doc: (tag, p, newWindow=true) => {
			return api.call('_/doc', { tag, p }).then(ret => {
				newWindow && window.open(ret.url, '_blank')
				return ret.url
			})
		},
		isAnon: (!jwt || !auth || !auth.uid),
		chkAcl,
		authProvDefault: () => {
			if(chkAcl('bs'))
				return 'BS'
			else if(chkAcl('cr'))
				return 'CR'
			else
				return '-'
		},
		getUid: (auth && auth.uid),
		login: (user, pass) => {
			api.call('auth/login', { user, pass }).then(ret => {
				if(ret && ret.jwt) {
					localStorage.setItem('jwt', ret.jwt)
					setJwt(ret.jwt)
				}
			})
		},
		logout: () => {
			setJwt(null)
			setAuth({})
			localStorage.removeItem('jwt')
		},
		alarmsQty: 0,
		msgError: txt => enqueueSnackbar(txt, { variant:'error' }),
		msgOk: txt => enqueueSnackbar(txt, { variant:'success' }),
		msgInfo: txt => enqueueSnackbar(txt, { variant:'info' }),
	}
	return api
}
