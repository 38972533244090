import React, { useState, useEffect, useContext, useCallback } from 'react'
import { useHistory, useParams } from "react-router-dom"
// import {useDropzone} from 'react-dropzone'
import Table, { setTabScan, TableSearch, useSearchQuery } from '../Table'

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

import CameraAltIcon from '@material-ui/icons/CameraAlt';
// import IconButton from '@material-ui/core/IconButton';
// import IconDelete from '@material-ui/icons/Delete'
// import CheckIcon from '@material-ui/icons/Check';
// import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
//import DeleteIcon from '@material-ui/icons/Delete';
// import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
// import CircularProgress from '@material-ui/core/CircularProgress';

import APICtx from '../lib/api'
import Window from '../Window'
import Button from '../Button'
// import ImgZoom from '../ImgZoom'

const useStyles = makeStyles((theme) => ({
	titleBar: {
		background:	'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
	},
	gridList: {
	},
	gridListTile: {
		width: "300px",
		height: "180px",
		"background-color": "green",
	},
	block: {
		width: 300,
	},
	blockMedia: {
		height: 200,
		cursor: "pointer",
	},
	blockDel: {
		marginLeft: "auto",
	},
	blockDZ: {
		height: 200,
		"padding": "0 !important",
	},
	dzIcon: {
		fontSize: 120,
		margin: "auto",
		color: "inherit",
	},
	paper: {
		padding: theme.spacing(2),
	},
	dropzone: {
		flex: 1,
		display: "flex",
		"flex-direction": "column",
		//padding: "20px",
		padding: "20px",
		width: "100%",
		height: "100%",
		color: "#888888",
		"align-items": "center",
		"border-width": "2px",
		"border-radius": "2px",
		"border-color": "#aaaaaa",
		"border-style": "dashed",
		"background-color": "#fafafa",
		outline: "none",
		//transition: "border .24s ease-in-out",
	},
}))

// function DZBlock({ onUpload }) {
// 	const classes = useStyles()
// 	const [ isUploading, setIsUploading ] = useState(false)

// 	const onDrop = useCallback(acceptedFiles => {
// 		if(acceptedFiles.length) {
// 			setIsUploading(true)
// 			onUpload(acceptedFiles).then(() => setIsUploading(false))
// 		}
// 	}, [])
// 	const {getRootProps, getInputProps, isDragActive} = useDropzone({
// 		onDrop,
// 		accept: 'image/*',
// 	})

// 	let statusIcon = isDragActive ?
// 				<CheckIcon className={classes.dzIcon} /> :
// 				( isUploading ? 
// 					<CircularProgress className={classes.dzIcon} size="120px" />
// 					:
// 					<AddAPhotoIcon className={classes.dzIcon} />
// 				)
// 	//let statusIcon = isDragActive ? <CheckIcon className={classes.dzIcon} /> : <AddAPhotoIcon className={classes.dzIcon} />

// 	return (
// 		<Grid item>
// 			<Card className={classes.block}>
// 				<CardContent className={classes.blockDZ}>
// 					<div {...getRootProps({className:classes.dropzone})}>
// 						<input {...getInputProps()} />
// 						{statusIcon}
// 					</div>
// 				</CardContent>
// 			</Card>
// 		</Grid>
// 	)
// }

// function ImgBlock({ _id:id, _uAuthor:uid, img:addr, time, onDelete, setZoom }) {
// 	const api = useContext(APICtx)
// 	const classes = useStyles()

// 	let btnDef = []
// 	if(api.chkAcl('admin') || api.getUid===uid)
// 		btnDef.push({ icon:<IconDelete />, onClick:()=>onDelete(id), tooltip:"Elimina", confirmTxt:'Confermare la cancellazione?' })

// 	return (
// 		<Grid item>
// 			<Card className={classes.block}>
// 				<CardMedia className={classes.blockMedia} image={addr} onClick={()=>setZoom(addr)} />
// 				<CardActions disableSpacing>
// 					<Typography variant="body2">{time}</Typography>
// 					{ onDelete && <Button def={btnDef} className={classes.blockDel} /> }
// 				</CardActions>
// 			</Card>
// 		</Grid>
// 	)
// }

// function DateBlock({ dateRaw, date, images, onDelete, onUpload, setZoom }) {
// 	const classes = useStyles()
// 	const api = useContext(APICtx)
// 	const { type, regNumber } = useParams()

// 	const handleDownload = e => api.doc('photos/zipDay', { type, regNumber, date:dateRaw, _id:regNumber })

// 	return (
// 		<Grid item>
// 			<Paper className={classes.paper}>
// 				<Typography variant="h5">
// 					{date}
// 					{ images.length>0 && (
// 						<IconButton color="primary" aria-label="upload picture" component="span" onClick={handleDownload}>
// 							<CloudDownloadIcon />
// 						</IconButton>
// 					)}
// 				</Typography>
// 				<Grid container justify="center" alignItems="stretch" spacing={2}>
// 					{images.map((item) => (
// 						<ImgBlock key={item._id} {...item} onDelete={onDelete} setZoom={setZoom} />
// 					))}
// 					{ onUpload && <DZBlock onUpload={onUpload} /> }
// 				</Grid>
// 			</Paper>
// 		</Grid>
// 	)
// }

export default function PhotoItem() {
	const api = useContext(APICtx)
	const history = useHistory()
	const [ scan, setScan ] = useState({ data:[], count:0 })
	// const [ imgZoomAddr, setImgZoomAddr ] = useState()
	const { type, regNumber } = useParams()

	// const handleDelete = _id => api.call('photos/delPhoto', { _id }).then(handleRefresh)
	// const handleUpload = files => api.call('photos/addPhoto', { type, regNumber }, files).then(handleRefresh)
	// const handleZoomClose = () => setImgZoomAddr(null)
	const handleShowDate = ({ key }) => history.push('/photos/'+type+'/'+regNumber+'/history/'+key)
	const handleRefresh = () => api.call('photos/scanHistory', { type, regNumber }).then(ret => {
		console.log('DATA', ret)
		setScan({ data:ret.data || [], count:ret.data.length || 0})
	})

	// eslint-disable-next-line
	useEffect(() => { handleRefresh() }, [])

	const tabDef = [
		{ key:'date', label:'Data caricamento' },
		{ key:'imagesQty', label:'Quantità immagini' },
		{ key:'_btns', content:row => (
			<Button row={row} def={btnDef} />
		)},
	]

	const btnDef = [
		{ icon:<CameraAltIcon />, onClick:handleShowDate, tooltip:"Visualizza foto" },
	]

	const itemName = type==='cart' ? 'Carrello' : 'Piattaforma'
	return (
		<Window title={itemName+' "'+regNumber+'"'} sidebar="staff">
			<Table def={tabDef}>{scan}</Table>
		</Window>
	)
}
