import React, { useState, useEffect, useContext, useRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Button, FormControl, InputLabel, InputAdornment, IconButton, FilledInput, OutlinedInput, TextField} from '@material-ui/core';
import { Box } from '@material-ui/core'
//import Typography from '@material-ui/core/Typography';
import { Checkbox as MuiCheckbox, Radio, Select as MuiSelect, RadioGroup, FormControlLabel, FormLabel } from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { Grid, Card, CardHeader, CardContent } from '@material-ui/core'
import { Menu, MenuItem, MenuList } from '@material-ui/core'
import { Popper, Grow, Paper, ClickAwayListener } from '@material-ui/core'
import { List, ListItem, ListItemText, DialogActions, DialogTitle, DialogContent, Dialog } from '@material-ui/core/'
import DateFnsUtils from '@date-io/date-fns'
import { it } from "date-fns/locale"

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconSearch from '@material-ui/icons/Search'

import APICtx from './lib/api'

const FormData = React.createContext({})

const useStyles = makeStyles((theme) => ({
	margin: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	inputTabTitle: {
		margin: 0,
	},
	autocBox: {
		zIndex: 10,
	},
}))

export function InputPicker({ scanCall, label, placeholder, name, onChange, icon, isRequired, isDisabled, isMulti }) {
	const data = useContext(FormData)
	let value = data.r[name] ? data.r[name] : ( isMulti ? [] : {} )

	function setValue(valueIdx, opt) {
		let valueNew

		if(!isMulti)
			valueNew = opt
		else {
			const optEmpty = !opt || !Object.keys(opt).length
			if(valueIdx<value.length) {
				if(optEmpty)
					valueNew = value.filter((mapRow, mapIdx) => mapIdx!==valueIdx)
				else
					valueNew = value.map((mapRow, mapIdx) => {
						return (valueIdx===mapIdx) ? opt : mapRow
					})
			}
			else if(!optEmpty)
				valueNew = value.concat([opt])
		}

		if(name)
			data.w(name, valueNew)
		if(onChange)
			onChange(valueNew)
	}
	if(!icon)
		icon = <IconSearch />
	if(isRequired)
		label += '*'

	const boxProps = { label, isDisabled, icon, placeholder, value, setValue, scanCall, valueIdx: 0 }
	if(isMulti) {
		let valueDraw = isDisabled ? value : value.concat([{}])
		return valueDraw.map((valueRow, valueIdx) => <InputPickerBox key={valueIdx} {...boxProps} value={valueRow} valueIdx={valueIdx} />)
	}
	else
		return <InputPickerBox {...boxProps} />
}

function InputPickerBox({ label, isDisabled, icon, placeholder, valueIdx, value, setValue, scanCall }) {
	const [ openPicker, setOpenPicker ] = useState(false)
	const valueFound = value && value.label
	
	function onChange(opt) {
		setOpenPicker(false)
		setValue(valueIdx, opt)
	}
	
	return (
		<FormControl fullWidth variant="outlined">
			<InputLabel>{label}</InputLabel>
			<OutlinedInput
				endAdornment={
					<InputAdornment position="end">
						<IconButton
							aria-label="Select picker"
							edge="end"
							onClick={() => setOpenPicker(true)}
							disabled={isDisabled}
						>{icon}</IconButton>
					</InputAdornment>
				}
				label={label}
				placeholder={placeholder}
				value={valueFound ? value.label : ''}
				readOnly={!!valueFound}
				disabled={!valueFound || isDisabled}
			/>
			<InputPickerPopup label={label} scanCall={scanCall} open={openPicker} setOpen={setOpenPicker} value={value} setValue={onChange} />
		</FormControl>
	)
}

function InputPickerPopup({ label, open, setOpen, scanCall, value, setValue }) {
	const api = useContext(APICtx)
	const [ search, setSearch ] = useState('')
	const [ options, setOptions ] = useState({ data:[] })

	useEffect(() => {
		if(open && scanCall)
			api.call(scanCall, { search }).then(setOptions)
	}, [ open, scanCall, search ])

	function onSelect(opt) {
		setSearch('')
		if(opt)
			setValue(opt)
		else
			setValue({})
	}

	const handleClose = () => {
		console.log('CLOSE DIALOG!')
		setOpen(false)
	}

	return (
		<Dialog
			disableBackdropClick
			disableEscapeKeyDown
			maxWidth="xs"
			aria-labelledby="confirmation-dialog-title"
			open={open}
			onClose={handleClose}
		>
			<DialogTitle id="confirmation-dialog-title">
				{ label && <>{label}<br /></> }
				<TextField fullWidth label="Cerca" value={search} onChange={e => setSearch(e.target.value)} />
			</DialogTitle>
			<DialogContent>
				<List>
					{options.data.map(opt => (
						<ListItem key={opt.id} button onClick={() => onSelect(opt)} selected={value && value.id===opt.id}>
							<ListItemText>{opt.label}</ListItemText>
						</ListItem>
					))}
				</List>
			</DialogContent>
			<DialogActions>
				<Button color="primary" onClick={() => onSelect()}>Svuota</Button>
				<Button color="primary" onClick={handleClose}>Annulla</Button>
			</DialogActions>
		</Dialog>
	)
}

function InputAutoc({ anchorEl, options, handleDone }) {
	const classes = useStyles()

	const open = Boolean(anchorEl) && options && options.length
	return (
		<Popper open={open} anchorEl={anchorEl} role={undefined} transition disablePortal className={classes.autocBox} placement="bottom-start">
			{({ TransitionProps }) => (
				<Grow
				{...TransitionProps}
				style={{ transformOrigin: 'left top' }}
				>
					<Paper>
						<MenuList>
							{ options.map(opt => <MenuItem onClick={()=>handleDone(opt.value)}>{opt.label || opt.value}</MenuItem> )}
						</MenuList>
					</Paper>
				</Grow>
			)}
        </Popper>
	)
}

export function Input({ name, label, placeholder, onChange, onFocus, onClick, icon, isRequired, isDisabled, multiline, multilineSize, isBlkChild, autocomplete }) {
	const styles = useStyles()
	const data = useContext(FormData)
	const [ autocAnchor, setAutocAnchor ] = useState(null)
	const [ autocOptions, setAutocOptions ] = useState([])

	const value = data.r[name] ? data.r[name] : ( isDisabled ? ' ' : '' )
	const handlerChange = (e) => {
		if(name)
			data.w(name, e.target.value)
		if(onChange)
			onChange(value, e.target.value)
		setAutocAnchor(e.target)
		autocomplete && autocomplete(e.target.value).then(ret => setAutocOptions(ret.data))
	}
	
	const startAdornment = icon ? (
		<InputAdornment position="start">
			{icon}
		</InputAdornment>
	) : ''

	const handleAutocDone = newValue => {
		setAutocAnchor()
		newValue && data.w(name, newValue)
	}
	const handleClickAway = () => setAutocAnchor(null)

	if(isRequired)
		label += '*'

	const Field = isDisabled ? FilledInput : OutlinedInput

	return (
		<ClickAwayListener onClickAway={handleClickAway}>
		<FormControl fullWidth className={styles.margin} variant={isDisabled ? 'filled' : "outlined"}>
			<InputLabel>{label}</InputLabel>
			
						
			<Field
				label={label}
				placeholder={placeholder}
				value={value}
				onChange={handlerChange}
				onFocus={onFocus}
				onClick={onClick}
				onBlurx={handleClickAway}
				startAdornment={startAdornment}
				disabled={Boolean(isDisabled)}
				multiline={multiline}
				rows={multiline && !isDisabled ? (multilineSize ? multilineSize : 5) : null}
			/>
			
			{ autocomplete && <InputAutoc anchorEl={autocAnchor} options={autocOptions} handleDone={handleAutocDone} /> }
		</FormControl>
		</ClickAwayListener>
	)
}

function InputTabRow({ idx, value, def, isDisabled, onChange }) {
	const styles = useStyles()

	const handleChange = (valSub, col) => {
		let valueNew = { ...value, [col]:valSub }
		onChange(valueNew, idx)
	}

	const inputs = def.map(defCol => (
		<InputTabField key={defCol.key} col={defCol.key} value={value[defCol.key]} def={defCol} isDisabled={isDisabled} onChange={handleChange} />
	))
	return (
		<Card className={styles.margin}>
			<CardHeader className={styles.inputTabTitle} title={'#'+(idx+1)} />
			<CardContent>
				{inputs}
			</CardContent>
		</Card>
	)
}

function InputTabField({ col, value, def, isDisabled, onChange }) {
	const styles = useStyles()
	const label = def.label
	const Field = isDisabled ? FilledInput : OutlinedInput

	const handleChange = (e) => {
		onChange(e.target.value, col)
	}

	return (
		<FormControl className={styles.margin} fullWidth={def.fullWidth} variant={isDisabled ? 'filled' : "outlined"}>
			<InputLabel>{label}</InputLabel>
			<Field
				label={label}
				placeholder={label}
				value={value}
				onChange={handleChange}
				disabled={isDisabled}
			/>
		</FormControl>
	)
}

export function InputTab({ name, label, isDisabled, def }) {
	const data = useContext(FormData)

	let value = data.r[name]
	if(value)
		value = value.filter(row => {
			for(let defCol of def)
				if(row[defCol.key])
					return true
			return false
		})
	else
		value = []
	if(!isDisabled)
		value.push({})
	value = value.map(row => {
		let row2 = {}
		for(let defCol of def)
			row2[defCol.key] = row[defCol.key] ? row[defCol.key] : ''
		return row2
	})
	
	const handleChange = (valSub, idxEdited) => {
		let valueNew = value.map((row, idx) => idx===idxEdited ? valSub : row )
		data.w(name, valueNew)
	}

	return value.map((row, idx) => (
		<InputTabRow key={idx} idx={idx} def={def} isDisabled={isDisabled} value={row} onChange={handleChange} />
	))
}

export function InputDate({ name, label, onChange, isRequired, isDisabled }) {
	const data = useContext(FormData)
	const value = data.r[name] ? data.r[name] : null

	function setValue(val) {
		//const y = val.getFullYear()
		//const m = val.getMonth()
		//const d = val.getDate()

		if(onChange)
			onChange(val)
		else if(name)
			data.w(name, val)
	}
	if(isRequired)
		label += '*'

	return (
		<Box mt={1} mb={1}>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
				<KeyboardDatePicker
					disabled={isDisabled}
					clearable
					value={value} 
					onChange={setValue} 
					inputVariant="outlined" 
					format="dd/MM/yyyy" 
					label={label}
				/>
			</MuiPickersUtilsProvider>
		</Box>
	)
}

export function InputTime({ name, label, onChange, isRequired, isDisabled }) {
	const data = useContext(FormData)
	const value = data.r[name] ? '2000-01-01T'+data.r[name]+':00.000' : null

	function setValue(valTs) {
		const valTime = valTs ? (valTs.getHours().toString().padStart(2, '0'))+':'+(valTs.getMinutes().toString().padStart(2, '0')) : null
		if(onChange)
			onChange(valTime)
		else if(name)
			data.w(name, valTime)
	}
	
	if(isRequired)
		label += '*'
	return (
		<Box mt={1} mb={1}>
			<MuiPickersUtilsProvider utils={DateFnsUtils} locale={it}>
				<KeyboardTimePicker 
					disabled={isDisabled}
					clearable 
					ampm={false}
					value={value} 
					onChange={setValue} 
					inputVariant="outlined" 
					label={label}
				/>
			</MuiPickersUtilsProvider>
		</Box>
	)
}

export function Select({ name, label, onChange, options, isRequired, isDisabled }) {
	const data = useContext(FormData)
	const value = data.r[name] ? data.r[name] : null

	function setValue(e) {
		const val = e.target.value
		if(name)
			data.w(name, val)
		if(onChange)
			onChange(val)
	}

	const opts = [{ value:'', label:'' }].concat(options)

	if(isRequired)
		label += '*'

	return (
		<FormControl variant="outlined" fullWidth>
			<InputLabel>{label}</InputLabel>
			<MuiSelect
				native
				value={value}
				onChange={setValue}
				label={label}
				disabled={Boolean(isDisabled)}
			>
				{ opts.map(opt => <option value={opt.value}>{opt.label}</option> )}
			</MuiSelect>
		</FormControl>
	)
}

export function Checkbox({ name, label, onChange, value, isRequired }) {
	const data = useContext(FormData)
	function setValue(e) {
		const val = e.target.checked ? 1 : 0
		if(name)
			data.w(name, val)
		if(onChange)
			onChange(val)
	}

	if(isRequired)
		label += '*'
	const checked = Boolean(data.r[name] && [1, '1', true].includes(data.r[name]))

	return (
		<FormControl fullWidth component="fieldset">
			<FormControlLabel
				checked={checked}
				control={<MuiCheckbox />}
				label={label}
				onChange={setValue}
			/>
		</FormControl>
	)
}

export function Radios({ name, label, def, onChange, isRequired }) {
	const data = useContext(FormData)
	const value = data.r[name] ? data.r[name] : null
	if(isRequired)
		label += '*'

	function setValue(e) {
		if(name)
			data.w(name, e.target.value)
		if(onChange)
			onChange(e.target.value)
	}

	return (
		<FormControl fullWidth component="fieldset">
			<FormLabel component="legend">{label}</FormLabel>
			<RadioGroup row style={{flexDirection:'column'}} aria-label={label} name={name} value={value} onChange={setValue}>
				{/* def.map(item => {
					return <FormControlLabel key={item.value} value={item.value} control={<Radio />} label={item.label} />
				}) */}
				{ def.map(item => (
					<div>
						<FormControlLabel key={item.value} value={item.value} control={<Radio />} label={item.label} />
					</div>
				))}
			</RadioGroup>
		</FormControl>
	)
}

export function Password({ name, label, onChange, icon }) {
	const styles = useStyles()
	const [ showPassword, setShowPassword ] = useState(false)
	const data = useContext(FormData)
	//const valueIn = data.r[name] ? data.r[name] : ''
	//const [ value, setValue ] = useState(valueIn)

	/*
	function handlerChange(e) {
		setValue(e.target.value)
	}
	function handlerBlur(e) {
		if(name)
			data.w(name, value)
		if(onChange)
			onChange(value)
	}
	*/

	const value = data.r[name] ? data.r[name] : ''
	const handlerChange = (e) => {
		if(name)
			data.w(name, e.target.value)
		if(onChange)
			onChange(value, e.target.value)
	}

	const btnEye = (
		<InputAdornment position="end">
			<IconButton
				aria-label="toggle password visibility"
				onClick={() => setShowPassword(!showPassword)}
				edge="end"
			>
				{showPassword ? <Visibility /> : <VisibilityOff />}
			</IconButton>
		</InputAdornment>
	)

	const startAdornment = icon ? (
		<InputAdornment position="start">
			{icon}
		</InputAdornment>
	) : ''

	return (
		<FormControl fullWidth className={clsx(styles.margin)} variant="outlined">
			<InputLabel htmlFor="password-field">{label}</InputLabel>
			<OutlinedInput
				id="password-field"
				type={showPassword ? 'text' : 'password'}
				value={value}
				onChange={handlerChange}
				startAdornment={startAdornment}
				endAdornment={btnEye}
				label={label}
			/>
		</FormControl>
	)
}

export function FormCard({ xs, sm, md, lg, xl, label, children }) {
	return (
		<Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl}>
			<Card>
				{ label && <CardHeader title={label} titleTypographyProps={{variant:'h6' }} /> }
				<CardContent>{children}</CardContent>
			</Card>
		</Grid>
	)
}
export default function Form({ children, onSubmit, btnLabel, btnIcon, data, setData, isCentered }) {
	const styles = useStyles()
	const submitRef = useRef()

	/*
	useEffect(() => {
		const listener = event => {
			if (event.code === "Enter" || event.code === "NumpadEnter")
				submitRef.current.focus()
		}
		document.addEventListener("keydown", listener)
		return () => {
			document.removeEventListener("keydown", listener)
		}
	}, [onSubmit])
	*/

	if(!btnLabel)
		btnLabel = 'Salva'

	if(!data)
		data = {}
	const dataProvider = {
		w: (name, value) => {
			setData({ ...data, [name]:value })
		},
		r: data,
	}

	const gridProps = {
		spacing: 2
	}
	if(isCentered) {
		gridProps.alignItems = 'center'
		gridProps.justify = 'center'
		//gridProps.style = { marginTop:'2rem' }
	}

	return (
		<>
			<FormData.Provider value={dataProvider}>
				<Grid container {...gridProps}>
					{children}
					<Grid item xs={12}>
						<Button variant="contained" className={clsx(styles.margin)} color="primary" startIcon={btnIcon} onClick={onSubmit} ref={submitRef}>
							{btnLabel}
						</Button>
					</Grid>
				</Grid>
			</FormData.Provider>
		</>
	)
}
