import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from "react-router-dom"

import IconEdit from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete';

import APICtx from '../lib/api'
import Window from '../Window'
import Button from '../Button'
import BtnCSV from '../BtnCSV'
import Table, { setTabScan, TableSearch, TableSearchSelect } from '../Table'

const types = [
	{ label:'-- Tutti --', value:'-' },
	{ label:'Frontali diesel', value:'fdiesel' },
	{ label:'Frontali elettrici', value:'felectric' },
	{ label:'Magazzino', value:'warehouse' },
	{ label:'Piattaforme aeree', value:'aerialp' },
]

export default function UsedList() {
	const api = useContext(APICtx)
	const history = useHistory()
	const [ scan, setScan ] = useState({ data:[], count:0 })
	const [ page, setPage ] = useState(0)
	const [ filterType, setFilterType ] = useState('-')
	const [ filterTxt, setFilterTxt ] = useState('')
	
	const handleRefresh = () => api.call('used/scan', { page, filterType, filterTxt }).then(ret => setTabScan(ret, setScan, page, setPage))
	// eslint-disable-next-line
	useEffect(handleRefresh, [ page, filterType, filterTxt ])

	const btnEdit = ({ _id }) => history.push('/used/edit/'+_id)
	const btnDel = ({ _id }) => api.call('used/del', { _id }).then(handleRefresh)
	
	const btnDef = [
		{ icon:<IconEdit />, onClick:btnEdit, tooltip:"Modifica" },
		{ icon:<DeleteIcon />, onClick:btnDel, tooltip:"Elimina", confirmTxt:"Confermi la rimozione della scheda?" },
	]

	const tabDef = [
		{ key:'_type', label:'Divisione', content:row => {
			let srcType = types.filter(type => type.value===row.type)
			return srcType.length ? srcType[0].label : '?'
		}},
		{ key:'brand', label:'Marca' },
		{ key:'model', label:'Modello' },
		{ key:'directory', label:'Cartella' },
		{ key:'attachQty', label:'Qtà allegati' },
		{ key:'_btns', content:row => {
			let rowBtns = api.chkAcl(['write']) ? btnDef : []
			return <Button row={row} def={rowBtns} />
		}},
	]

	const fab = api.chkAcl(['write']) && { linkTo:'/used/edit' }
	return (
		<Window title="Schede usati" sidebar="staff" fab={fab}>
			<TableSearch name="txt" label="Cerca" setValue={setFilterTxt} />
			<TableSearchSelect label="Divisione" value={filterType} setValue={setFilterType} options={types} />
			<BtnCSV tag="used/csv" filters={{ filterType, filterTxt }} />
			<Table def={tabDef} page={page} setPage={setPage}>{scan}</Table> 
		</Window>
	)
}