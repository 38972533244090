import React, { useState, useEffect, useContext } from 'react'
import { useParams } from "react-router-dom"

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';

import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import APICtx from '../lib/api'
import Window from '../Window'
import ImgZoom from '../ImgZoom'

const useStyles = makeStyles((theme) => ({
	titleBar: {
		background:	'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
	},
	gridList: {
	},
	gridListTile: {
		width: "300px",
		height: "180px",
		"background-color": "green",
	},
	block: {
		width: 300,
	},
	blockMedia: {
		height: 200,
		cursor: "pointer",
	},
	blockDel: {
		marginLeft: "auto",
	},
	blockDZ: {
		height: 200,
		"padding": "0 !important",
	},
	dzIcon: {
		fontSize: 120,
		margin: "auto",
		color: "inherit",
	},
	paper: {
		padding: theme.spacing(2),
	},
	dropzone: {
		flex: 1,
		display: "flex",
		"flex-direction": "column",
		//padding: "20px",
		padding: "20px",
		width: "100%",
		height: "100%",
		color: "#888888",
		"align-items": "center",
		"border-width": "2px",
		"border-radius": "2px",
		"border-color": "#aaaaaa",
		"border-style": "dashed",
		"background-color": "#fafafa",
		outline: "none",
		//transition: "border .24s ease-in-out",
	},
}))

function ImgBlock({ addr, time, setZoom }) {
	const classes = useStyles()

	return (
		<Grid item>
			<Card className={classes.block}>
				<CardMedia className={classes.blockMedia} image={addr} onClick={()=>setZoom(addr)} />
				<CardActions disableSpacing>
					<Typography variant="body2">{time}</Typography>
				</CardActions>
			</Card>
		</Grid>
	)
}

function DateBlock({ dateRaw, date, images, setZoom }) {
	const classes = useStyles()
	const api = useContext(APICtx)
	const { type, regNumber } = useParams()

	const handleDownload = e => api.doc('photos/zipDay', { type, regNumber, date:dateRaw, _id:regNumber })

	return (
		<Grid item>
			<Paper className={classes.paper}>
				<Typography variant="h5">
					{date}
					{ images.length>0 && (
						<IconButton color="primary" aria-label="upload picture" component="span" onClick={handleDownload}>
							<CloudDownloadIcon />
						</IconButton>
					)}
				</Typography>
				<Grid container justify="center" alignItems="stretch" spacing={2}>
					{images.map((item) => (
						<ImgBlock key={item._id} {...item} setZoom={setZoom} />
					))}
				</Grid>
			</Paper>
		</Grid>
	)
}

export default function PhotoItem() {
	const api = useContext(APICtx)
	const [ images, setImages ] = useState([])
	const [ imgZoomAddr, setImgZoomAddr ] = useState()
	const { type, regNumber, date } = useParams()

	// const handleDelete = _id => api.call('photos/delPhoto', { _id }).then(handleRefresh)
	// const handleUpload = files => api.call('photos/addPhoto', { type, regNumber }, files).then(handleRefresh)
	const handleZoomClose = () => setImgZoomAddr(null)
	const handleRefresh = () => api.call('photos/scanDate', { type, regNumber, date }).then(ret => setImages(ret.data))

	// eslint-disable-next-line
	useEffect(handleRefresh, [])

	const dateDisplay = date.split('-').reverse().join('/')
	const title = (type==='cart' ? 'Carrello' : 'Piattaforma') + ' "'+regNumber+'" - ' + dateDisplay
	return (
		<Window title={title} sidebar="staff">
			<Grid container direction="column" justify="center" alignItems="stretch" spacing={2}>
				<DateBlock
					dateRaw={date}
					date={dateDisplay}
					images={images}
					setZoom={setImgZoomAddr}
				/>
				<ImgZoom label="Dettaglio foto" addr={imgZoomAddr} onClose={handleZoomClose} />
			</Grid>
		</Window>
	)
}
