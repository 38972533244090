import React from 'react'
import PinchZoomPan from "react-responsive-pinch-zoom-pan"

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import CloseIcon from '@material-ui/icons/Close';
import GetAppIcon from '@material-ui/icons/GetApp';

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: "relative",
	},
	title: {
		flexGrow: 1,
	},
	btnTool: {
		display: "flex",
		justifyContent: "flex-end",
	},
	container: {
		width: "100%",
		height: "90%",
		margin: "auto",
	},
	image: {
		margin: "auto",
	},
}))

export default function ImgZoom({ label, addr, onClose }) {
	const classes = useStyles()

	const handleDownload = () => window.open(addr, '_blank')

	return (
		<Dialog fullScreen open={!!addr} onClose={onClose}>
			<AppBar className={classes.appBar}>
				<Toolbar>
					<Typography variant="h6" className={classes.title}>{label}</Typography>
					<IconButton className={classes.btnTool} edge="end" color="inherit" onClick={handleDownload} aria-label="close">
						<GetAppIcon />
					</IconButton>
					<IconButton className={classes.btnTool} edge="end" color="inherit" onClick={onClose} aria-label="close">
						<CloseIcon />
					</IconButton>
				</Toolbar>
			</AppBar>
			<div className={classes.container}>
				<PinchZoomPan position="center">
					<img className={classes.image} src={addr} alt="Full-screen item" />
				</PinchZoomPan>
			</div>
		</Dialog>
	)
}
