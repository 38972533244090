import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import DescriptionIcon from '@material-ui/icons/Description';

import APICtx from './lib/api'

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		textAlign: "right",
	},
}))

export default function BtnCSV({ tag, filters={} }) {
	const api = useContext(APICtx)
	const classes = useStyles()

	const handleClick = () => api.doc(tag, filters)

	return (
		<div className={classes.root}>
			<Button variant="outlined" color="primary" onClick={handleClick}>
				<DescriptionIcon />
				CSV
			</Button>
		</div>
	)
}