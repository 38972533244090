import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from "react-router-dom"

import IconEdit from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete';
import PrintIcon from '@material-ui/icons/Print';
import CheckIcon from '@material-ui/icons/Check';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
//import PageviewIcon from '@material-ui/icons/Pageview';

import APICtx from '../lib/api'
import Window from '../Window'
import Button from '../Button'
import BtnCSV from '../BtnCSV'
import Table, { setTabScan, TableSearch, TableSearchSelect } from '../Table'

export const types = [
	{ label:'-- Tutti --', value:'-' },
	{ label:'Vendita nuovo', value:'saleNew' },
	{ label:'Vendita usato', value:'saleUsed' },
	{ label:'Noleggio', value:'rent' },
]
const authorsBase = [
	{ value:'-', label:'-- Tutti --' }
]

export default function UsedList() {
	const api = useContext(APICtx)
	const history = useHistory()
	const [ scan, setScan ] = useState({ data:[], count:0 })
	const [ page, setPage ] = useState(0)
	const [ authors, setAuthors ] = useState(authorsBase)
	const [ filterType, setFilterType ] = useState('-')
	const [ filterDest, setFilterDest ] = useState('')
	const [ filterAuthor, setFilterAuthor ] = useState('')
	
	const handleRefresh = () => {
		api.call('offers/scan', { page, filterType, filterDest, filterAuthor }).then(ret => setTabScan(ret, setScan, page, setPage))
		api.call('offers/authors').then(ret => {
			const retAuthors = ret.data.map( ({ id, label }) => ({ label, value:id }) )
			setAuthors(authorsBase.concat(retAuthors) )
		})
	}
	// eslint-disable-next-line
	useEffect(handleRefresh, [ page, filterType, filterDest, filterAuthor ])

	const btnEdit = ({ _id }) => history.push('/offers/edit/'+_id)
	const btnAccept = ({ _id }) => api.call('offers/accept', { _id }).then(handleRefresh)
	const btnUnaccept = ({ _id }) => api.call('offers/unaccept', { _id }).then(handleRefresh)
	const btnDel = ({ _id }) => api.call('offers/del', { _id }).then(handleRefresh)
	const btnPrint= ({ _id }) => api.doc('offers/pdf', {_id})
	
	const btnDef = {
		print: { icon:<PrintIcon />, onClick:btnPrint, tooltip:"Genera stampa" },
		accept: { icon:<AssignmentTurnedInIcon />, onClick:btnAccept, tooltip:"Offerta accettata" },
		unaccept: { icon:<AssignmentLateIcon />, onClick:btnUnaccept, tooltip:"Revoca accettazione" },
		//view: { icon:<PageviewIcon />, onClick:btnEdit, tooltip:"Visualizza" },
		edit: { icon:<IconEdit />, onClick:btnEdit, tooltip:"Modifica" },
		delete: { icon:<DeleteIcon />, onClick:btnDel, tooltip:"Elimina", confirmTxt:"Confermi la rimozione dell'offerta?" },
	}

	const tabDef = [
		{ key:'code', label:'Numero' },
		{ key:'_tsMake', label:'Data', type:'DATE' },
		{ key:'_uAuthorLabel', label:'Commerciale' },
		{ key:'_type', label:'Tipologia', content:row => {
			let srcType = types.filter(type => type.value===row.type)
			return srcType.length ? srcType[0].label : '?'
		}},
		{ key:'destName', label:'Ragione sociale' },
		{ key:'productName', label:'Prodotto' },
		{ key:'price', label:'Prezzo', type:'MONEY' },
		{ key:'_accepted', label:'Accettata', content:row=> {
			return row?.tags?.includes('accepted') && <CheckIcon />
		}},
		{ key:'_btns', content:row => {
			const rowBtns = [ btnDef.print ]
			if(api.chkAcl(['write']) && api.chkAcl(['offersAdmin']))
				rowBtns.push(btnDef.edit)
			if(api.chkAcl(['write']) && api.chkAcl(['offers']))
				row?.tags?.includes('accepted') ? rowBtns.push(btnDef.unaccept) : rowBtns.push(btnDef.accept)
			if(api.chkAcl(['admin']))
				rowBtns.push(btnDef.delete)
			return <Button row={row} def={rowBtns} />
		}},
	]

	const fab = api.chkAcl(['write']) && { linkTo:'/offers/edit' }
	return (
		<Window title="Offerte" sidebar="staff" fab={fab}>
			<TableSearch name="txt" label="Cerca per cliente" setValue={setFilterDest} />
			<TableSearchSelect label="Tipologia" value={filterType} setValue={setFilterType} options={types} />
			<TableSearchSelect label="Commerciale" value={filterAuthor} setValue={setFilterAuthor} options={authors} />
			<BtnCSV tag="offers/csv" filters={{ filterType, filterDest }} />
			<Table def={tabDef} page={page} setPage={setPage}>{scan}</Table>
		</Window>
	)
}