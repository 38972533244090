import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from "react-router-dom"

import IconEdit from '@material-ui/icons/Edit'
//import IconDelete from '@material-ui/icons/Delete'
import PrintIcon from '@material-ui/icons/Print';

import APICtx from '../lib/api'
import Window from '../Window'
import Button from '../Button'
import Table, { setTabScan } from '../Table'

export default function TiresList() {
	const api = useContext(APICtx)
	const history = useHistory()
	const [ scan, setScan ] = useState({ data:[], count:0 })
	const [ page, setPage ] = useState(0)
	//const [ edits, setEdits ] = useState(0)
	
	useEffect(() => {
		api.call('tires/scan', { page }).then(ret => setTabScan(ret, setScan, page, setPage))
		// eslint-disable-next-line
	}, [ page ])

	const btnEdit = ({ _id }) => {
		history.push('/tires/edit/'+_id)
	}
	const btnPrint= ({ _id }) => {
		api.doc('tires/pdf', {_id})
	}

	const btnDef = [
		{ icon:<IconEdit />, onClick:btnEdit, tooltip:"Modifica" },
		{ icon:<PrintIcon />, onClick:btnPrint, tooltip:"Genera stampa" },
	]

	const tabDef = [
		{ key:'_seq', label:'# Mod.' },
		{ key:'_tsMake', label:'Data', type:'DATE' },
		{ key:'number', label:'Commessa' },
		{ key:'customerName', label:'Cliente' },
		{ key:'_uOwnerLabel', label:'Autore' },
		{ key:'_uAuthorLabel', label:'Modificato da' },
		{ key:'_btns', content:row => (
			<Button row={row} def={btnDef} />
		)},
	]

	return (
		<Window title="Identificazione gomme" sidebar="staff" fab={{ linkTo:'/tires/edit' }}>
			<Table def={tabDef} page={page} setPage={setPage}>{scan}</Table> 
		</Window>
	)
}
