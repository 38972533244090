import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from "react-router-dom"

import EditIcon from '@material-ui/icons/Edit'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes';

import APICtx from '../lib/api'
import Window from '../Window'
import Button from '../Button'
import Table, { setTabScan, TableSearch, useSearchQuery } from '../Table'

export default function PAPrepList() {
	const api = useContext(APICtx)
	const query = useSearchQuery()
	const history = useHistory()
	const [ scan, setScan ] = useState({ data:[], count:0 })
	const [ page, setPage ] = useState(0)
	//const [ edits, setEdits ] = useState(0)
	const regNumber = query.get('regNumber')
	
	useEffect(() => {
		api.call('piattaforma/scanPrep', { regNumber, page }).then(ret => setTabScan(ret, setScan, page, setPage))
		// eslint-disable-next-line
	}, [ regNumber, page ])

	const btnEdit = ({ _id }) => {
		history.push('/pa-preparation/edit/'+_id)
	}

	const btnDef = [
		{ icon:<EditIcon />, onClick:btnEdit, tooltip:"Modifica" },
	]

	const tabDef = [
		{ key:'regNumber', label:'Num. macchina' },
		{ key:'_ts', label:'Data', type:'DATE' },
		{ key:'_btns', content:row => (
			<Button row={row} def={btnDef} />
		)},
	]

	//const fabDef = regNumber ? { icon:<CameraAltIcon />, linkTo:'/pa-photo/'+regNumber } : null
	const fabDef = null

	return (
		<Window title="Preparazioni piattaforme" sidebar="staff" fab={{ linkTo:'/pa-preparation/done' }}>
			<TableSearch name="regNumber" label="Seleziona matricola" icon={<SpeakerNotesIcon />} />
			<Table def={tabDef} page={page} setPage={setPage}>{scan}</Table> 
		</Window>
	)
}
