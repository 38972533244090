import React, { useState, useEffect, useContext } from 'react';
import { useParams, useHistory } from "react-router-dom"

import APICtx from '../lib/api'
import Window from '../Window'
import Form, { FormCard, Input, Radios } from '../Form'
import { FormCart } from '../Socar'

export default function TiresEdit() {
	const api = useContext(APICtx)
	const [ data, setData ] = useState({})
	const [ anagrLock, setAnagrLock ] = useState(false)
	const { _id } = useParams()
	const history = useHistory()

	const onSubmit = () => {
		api.call('tires/set', { _id, ...data }).then(ret => {
			if(ret && ret._id)
				history.replace('/tires/edit/'+ret._id)
		})
	}

	useEffect(() => {
		if(_id)
			api.call('tires/get', { _id }).then(ret => {
				setAnagrLock(null)
				setData(ret.data)
			})
		// eslint-disable-next-line
	}, [])

	const customerVat = data.customerVat ? data.customerVat : null
	useEffect(() => {
		if(customerVat)
			api.call('anagr/getByVat', { vat:customerVat }).then(ret => {
				if(ret._id) {
					if(anagrLock!==null)
						setData(Object.assign({}, data, {
							customerName:		ret.data.name,
							customerTaxCode:	ret.data.taxCode,
						}))
					setAnagrLock(true)
				}
				else
					setAnagrLock(false)
			})
		else
			setAnagrLock(false)
		// eslint-disable-next-line
	}, [customerVat])

	return (
		<Window title="Mod. ident. gomme" sidebar="staff">
			<Form onSubmit={onSubmit} btnLabel="Salva" data={data} setData={setData}>
				<FormCard xs={12}>
					<Input name="number" label="Commessa" />
				</FormCard>
				<FormCard xs={12} md={6} label="Cliente">
					<Input name="customerVat" label="P.IVA cliente" isRequired />
					<Input name="customerName" label="Denominazione cliente" isRequired isDisabled={anagrLock} />
				</FormCard>
				<FormCart />
				<FormCard xs={12} md={6} label="Gomme anteriori">
					<Input name="aSize" label="Misura" isRequired />
					<Input name="aDiamInt" type="number" label="Diametro interno (mm)" />
					<Input name="aHolesQty" type="number" label="Numero fori" />
					<Input name="aHolesDiam" type="number" label="Diametro fori (mm)" />
					<Input name="aFlanga" type="number" label="Spostamento flangia (mm)" />
					<Radios name="aIssues" label="Si risontrano rotture, criccature oppure ovalizzazioni fori ai cerchi?" isRequired def={[
						{ value:"yes", label:"Si" },
						{ value:"no", label:"No" },
						{ value:"dx", label:"Destro" },
						{ value:"sx", label:"Sinistro" },
						{ value:"both", label:"Entrambi" },
					]} />
				</FormCard>
				<FormCard xs={12} md={6} label="Gomme posteriori">
					<Input name="pSize" label="Misura" isRequired />
					<Input name="pDiamInt" type="number" label="Diametro interno (mm)" />
					<Input name="pHolesQty" type="number" label="Numero fori" />
					<Input name="pHolesDiam" type="number" label="Diametro fori (mm)" />
					<Input name="pFlanga" type="number" label="Spostamento flangia (mm)" />
					<Radios name="pIssues" label="Si risontrano rotture, criccature oppure ovalizzazioni fori ai cerchi?" isRequired def={[
						{ value:"yes", label:"Si" },
						{ value:"no", label:"No" },
						{ value:"dx", label:"Destro" },
						{ value:"sx", label:"Sinistro" },
						{ value:"both", label:"Entrambi" },
					]} />
				</FormCard>
			</Form>
		</Window>
	)
}
