import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from "react-router-dom"

import IconEdit from '@material-ui/icons/Edit'
import IconDelete from '@material-ui/icons/Delete'
import PrintIcon from '@material-ui/icons/Print';
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import AttachFileIcon from '@material-ui/icons/AttachFile';

import APICtx from '../lib/api'
import Window from '../Window'
import Button from '../Button'
import BtnCSV from '../BtnCSV'
import Table, { setTabScan, TableSearch, TableSearchSelect } from '../Table'

export default function ReqSupportList() {
	const api = useContext(APICtx)
	const history = useHistory()
	const [ scan, setScan ] = useState({ data:[], count:0 })
	const [ page, setPage ] = useState(0)
	const [ edits, setEdits ] = useState(0)
	const [ filterOffice, setFilterOffice ] = useState(api.authProvDefault())
	const [ filterTxt, setFilterTxt ] = useState('')

	const handleRefresh = () => api.call('reqsupport/scan', { page, filterOffice, filterTxt }).then(ret => setTabScan(ret, setScan, page, setPage))
	// eslint-disable-next-line
	useEffect(handleRefresh, [ page, edits, filterOffice, filterTxt ])

	const btnEdit = ({ _id }) => history.push('/reqsupport/edit/'+_id)
	const btnAssign = ({ _id }) => {
		api.call('reqsupport/assign', { _id }).then(ret => ret && setEdits(edits+1))
	}
	const btnUnassign = ({ _id }) => {
		api.call('reqsupport/unassign', { _id }).then(ret => ret && setEdits(edits+1))
	}
	const btnDel = ({ _id }) => {
		api.call('reqsupport/del', { _id }).then(ret => {
			if(ret)
				setEdits(edits+1)
		})
	}
	const btnPrint = ({ _id }) => {
		window.open('/reqsupport/print/'+_id, '_blank')
		setTimeout(handleRefresh, 2000)
	}
	const handleAttachments = ({ _seq }) => history.push('/attachments/reqsupport/'+_seq)

	const btnDef = {
		edit:		{ icon:<IconEdit />, onClick:btnEdit, tooltip:"Modifica" },
		attachments:{ icon:<AttachFileIcon />, onClick:handleAttachments, tooltip:"Visualizza allegati" },
		assign:		{ icon:<AssignmentLateIcon />, onClick:btnAssign, tooltip:"Prendi in carico" },
		unassign:	{ icon:<AssignmentTurnedInIcon />, onClick:btnUnassign, tooltip:"Revoca presa in carico", confirmTxt:'Confermare la revoca della presa in carico?' },
		print:		{ icon:<PrintIcon />, onClick:btnPrint, tooltip:"Genera stampa" },
		delete:		{ icon:<IconDelete />, onClick:btnDel, tooltip:"Elimina", confirmTxt:'Confermare la cancellazione?' },
	}

	const tabDef = [
		{ key:'_seqCustom', label:'# Mod.', content:row => 'RA-'+row._seqNum+'/'+row._seqYear },
		{ key:'_tsMake', label:'Data', type:'DATETIME' },
		{ key:'customerName', label:'Cliente' },
		{ key:'itemAddressProv', label:'Prov.' },
		{ key:'_uOwnerLabel', label:'Autore' },
		//{ key:'_uAuthorLabel', label:'Modificato da' },
		{ key:'_assignee', label:'Assegnato a' },
		{ key:'attachQty', label:'Qtà allegati' },
		{ key:'_btns', content:row => {
			let rowBtns = []
			if(row.isEditable)
				rowBtns.push(btnDef.edit)
			else if(row.attachQty)
				rowBtns.push(btnDef.attachments)
			if(!row._uAssignee && api.chkAcl(['write']) && api.chkAcl(['carrelliAdmin']))
				rowBtns.push(btnDef.assign)
			//else if(api.getUid===row._uAssignee)
			else if(row._uAssignee && api.chkAcl(['write']) && api.chkAcl(['admin']))
				rowBtns.push(btnDef.unassign)
			rowBtns.push(btnDef.print)
			if(api.chkAcl(['admin']))
				rowBtns.push(btnDef.delete)
			return <Button row={row} def={rowBtns} />
		}},
	]

	const fab = api.chkAcl(['write']) && { linkTo:'/reqsupport/edit' }
	return (
		<Window title="Rich. assistenza" sidebar="staff" fab={fab}>
			<TableSearch name="txt" label="Cerca" setValue={setFilterTxt} />
			<TableSearchSelect label="Sede" value={filterOffice} setValue={setFilterOffice} options={[
				{ label:'Brescia', value:'BS' },
				{ label:'Cremona', value:'CR' },
				{ label:'-- Tutti --', value:'-' },
			]} />
			<BtnCSV tag="reqsupport/csv" filters={{ filterOffice, filterTxt }} />
			<Table def={tabDef} page={page} setPage={setPage}>{scan}</Table> 
		</Window>
	)
}