import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FabMui from '@material-ui/core/Fab';

import ToolBar from './ToolBar'
import SideBar from './SideBar'
import MenuStaff from './menu/Staff'

import AddIcon from '@material-ui/icons/Add'

const useStyles = makeStyles(theme => ({
	root: {
		display: 'flex',
		flexGrow: 1,
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: theme.sidebar.width,
			flexShrink: 0,
		},
	},
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		width:"100%",
		overflowX:"hidden",
		height:"100vh",
	},
	floatFab: {
		position: "absolute", 
		bottom: theme.spacing(3),
		right: theme.spacing(3),
	},
}))

const sidebars = {
	staff: <MenuStaff />,
	//search: <MenuSearch />,
}

function FabArea({ linkTo, onClick, icon }) {
	const styles = useStyles()
	let btnProps = {
		color:		'primary',
		className:	styles.floatFab,
	}
	if(linkTo) {
		btnProps.component = Link
		btnProps.to = linkTo
	}
	if(!icon)
		icon = <AddIcon />
	return <FabMui {...btnProps}>{icon}</FabMui>
}

export default function Window({ children, sidebar, title, fab }) {
	const [ sidebarActive, setSidebarActive ] = useState(false)
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<ToolBar title={title} setSidebarActive={setSidebarActive} sidebarEmpty={!sidebars[sidebar]} />
			{ sidebars[sidebar] &&
				<nav className={classes.drawer} aria-label="menu laterale">
					<SideBar sidebarActive={sidebarActive} setSidebarActive={setSidebarActive}>{sidebars[sidebar]}</SideBar>
				</nav>
			}
			<main className={classes.content}>
				<div className={classes.toolbar} />
				<Grid container spacing={3}>
					{children}
				</Grid>
			</main>
			{ fab && <FabArea {...fab} /> }
		</div>
	)
}
